import React, { useState, forwardRef, useImperativeHandle } from "react";
import _uniqBy from "lodash/uniqBy";
import { SelectAsyncPaginate } from "../../../../shared/components/Input/Select";
import { Option } from "react-select/src/filters";
import { fetchContactFiles } from "../../../Contacts/services";
import "../../styles/contactsSelector.scss";

type Props = {
    // onSubmit: (error: string | null, result: { selectAll: boolean; participants: Array<string> } | null) => void;
};

interface IOption extends Option {
    id: number;
    label: string;
}

type Result = {
    file: number | null;
};
export interface IOnFileSubmit {
    error: string | null;
    result: Result | null;
}

const AddCollectorContactsFile = forwardRef((props: Props, ref) => {
    const [errors, setErrors] = useState<{ select: string; general: string }>({ select: "", general: "" });
    const [file, setFile] = useState<number|null>(null);
    const [files, setFiles] = useState<{ data: Array<IOption>; total: number }>({ data: [], total: 0 });
    const [fileName, setFileName] = useState<string>("");
    useImperativeHandle(ref, () => ({
        onSubmit(): IOnFileSubmit {
            console.log(file)
            const result = {
                file: null,
            };
            if (!file) {
                const error = "Please choose file";
                setErrors({ ...errors, select: error });
                return {
                    error,
                    result: null,
                };
            }

            result.file = file

            if (errors.general || errors.select) setErrors({ ...errors, general: "", select: "" });


            return {
                error: null,
                result,
            };
        },
    }));

    const onFilesChange = async (option: IOption) => {
      setFile(option.id)
      setFileName(option.label)
  };

    const loadOptions = async (paginationData, loadedOptions: Array<IOption>) => {
        try {
            const resData = await fetchContactFiles(paginationData);
            if (resData.result && resData.data) {
                const options = resData.data.map((file) => ({
                    label: file.fileName,
                    value: file.id,
                    id: file.id,
                }));

                const newFiles = _uniqBy([...loadedOptions, ...files.data, ...options], "id");
                setFiles({ data: newFiles, total: resData.total });
                return options;
            }
        } catch (error) {
            console.log("loadOptions error", error);
            return [];
        }
    };


    return (
        <div className="contact-files-selector-container">
            <SelectAsyncPaginate
                handleSelectChange={onFilesChange}
                name="file"
                isMulti={false}
                isClearable={true}
                isSearchable={true}
                error={errors.select}
                selectedOptions={file ? [file] : []}
                className="custom-select"
                selectAll={false}
                loadOptions={loadOptions}
                total={files.total}
                placeholder={fileName || "Choose file"}
            />
        </div>
    );
});

export default AddCollectorContactsFile;

