import React, { useState, useRef } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { FieldInputProps, FormikProps } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import { PAGINATION_LIMIT } from "../../constants";
import { isEmpty } from "../../../utils/helpers";

type Option = {
  label: string;
  value: string;
};

type Props = {
  options: Option[];
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  handleSelectChange?: (value: string) => void;
  defaultValue?: Option;
  placeholder?: string;
  className?: string;
  isMulti?: boolean;
  error?: string | null;
  scrollable?: boolean;
  scrollToElement: (id: number) => void;
  id?: number;
  value?: Option;
};

export const SelectField = (props: Props) => {
  const { options, field, form, defaultValue, error } = props;
  return (
    <div className={`input-block${error ? " input-error" : ""}`} id={"" + props.id}>
      <Select
        defaultValue={defaultValue}
        name={field.name}
        isMulti={props.isMulti}
        value={Boolean(options) ? options.find((option) => option.value === field.value) : null}
        onChange={(option: Option) => {
          form.setFieldValue(field.name, option.value);
          if (typeof props.handleSelectChange === "function") {
            props.handleSelectChange(option.value);
          }
          if (props.scrollable) {
            props.scrollToElement(props.id);
          }
        }}
        onBlur={field.onBlur}
        isSearchable={false}
        options={options}
        className={props.className}
        classNamePrefix={props.className}
        placeholder={props.placeholder}
        components={{ Option: CustomSelectOption }}
      />
      {error && <p className="error-text">{form.errors[field.name]}</p>}
    </div>
  );
};

interface SelectPlainProps {
  options: any;
  name: string;
  error?: string | null;
  handleSelectChange: (value) => void;
  defaultValue?: Option;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  selectedOptions?: Option | Option[];
  className?: string;
  placeholder?: string;
}

export const SelectPlain = (props: SelectPlainProps) => {
  const { options, name, error, defaultValue } = props;

  return (
    <div className={`input-block ${error ? " input-error" : ""}`}>
      <Select
        defaultValue={defaultValue}
        name={name}
        onChange={(option: Option | Option[]) => {
          props.handleSelectChange(option);
        }}
        isSearchable={props.isSearchable}
        options={options}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        placeholder={props.placeholder}
        className={props.className}
      />
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

type PaginationDataType = {
  hasMore?: boolean;
  start: number;
  length: number;
  msisdn?: string;
};

interface SelectPaginateProps extends Omit<SelectPlainProps, "selectedOptions" | "options"> {
  loadOptions: (paginationData: PaginationDataType, loadedOptions) => Promise<Array<Option>>;
  selectedOptions: Array<number>;
  selectAll?: boolean;
  total: number;
  initialPlaceholder?: string;
}

export const SelectAsyncPaginate = (props: SelectPaginateProps) => {
  const Option = (optionProps) => {
    let selected = false;
    const isOptionSelected = props.selectedOptions.includes(optionProps.data.id);

    if (props.selectAll && isOptionSelected) {
      selected = false;
    } else if (!props.selectAll && isOptionSelected) {
      selected = true;
    } else if (props.selectAll && !isOptionSelected) {
      selected = true;
    }

    return (
      <components.Option {...optionProps} className={selected ? "custom-select__option--is-selected" : ""}>
        <label>{optionProps.label}</label>
      </components.Option>
    );
  };

  const [isLoading, setLoading] = useState(false);
  const paginationData = useRef<PaginationDataType>({ hasMore: true, start: 0, length: PAGINATION_LIMIT });

  const searchData = useRef<{ msisdn: string; start: number; length: number; hasMore?: boolean }>({
    msisdn: "",
    start: 0,
    length: PAGINATION_LIMIT,
    hasMore: true,
  });

  const loadOptions = async (searchValue: any, loadedOptions: any) => {
    const result = {
      hasMore: true,
      options: loadedOptions,
    };

    try {
      setLoading(true);
      if (searchValue) {
        searchData.current.msisdn = searchValue;
        const options = await props.loadOptions(searchData.current, loadedOptions);

        if ((!isEmpty(options) && options.length < PAGINATION_LIMIT) || isEmpty(options)) {
          searchData.current.hasMore = false;
        }
        if (!isEmpty(options)) {
          searchData.current.start += options.length;
          result.options = options;
        }
        result.hasMore = searchData.current.hasMore;
        setLoading(false);
        return result;
      } else {
        const options = await props.loadOptions(paginationData.current, loadedOptions);
        if ((!isEmpty(options) && options.length < PAGINATION_LIMIT) || isEmpty(options)) {
          paginationData.current.hasMore = false;
        }
        if (!isEmpty(options)) {
          paginationData.current.start += options.length;
          result.options = options;
        }
        result.hasMore = paginationData.current.hasMore;
        setLoading(false);
        return result;
      }
    } catch (error) {
      return result;
    }
  };

  const onInputChange = (value: string) => {
    if (!value) {
      searchData.current.msisdn = "";
    }
    searchData.current.start = 0;
    searchData.current.hasMore = true;
  };

  let placeholder = props.initialPlaceholder;
  if (props.isMulti) {
    if (props.selectAll) {
      const sum = (props.total - props.selectedOptions.length) | 0;
      placeholder = `Selected ${sum} ${props.name}${sum > 1 ? "s" : ""}`;
    } else if (!isEmpty(props.selectedOptions)) {
      placeholder = `Selected ${props.selectedOptions.length} ${props.name}${
        props.selectedOptions.length > 1 ? "s" : ""
      }`;
    }
  }

  const { name, error } = props;

  return (
    <div className={`input-block${error ? " input-error" : ""}`}>
      <AsyncPaginate
        name={name}
        value="Select"
        onChange={(option: Option | Option[]) => {
          props.handleSelectChange(option);
        }}
        defaultOptions
        onInputChange={onInputChange}
        isMulti={props.isMulti}
        loadOptions={loadOptions}
        isLoading={isLoading}
        isSearchable={props.isSearchable}
        className={props.className}
        classNamePrefix={props.className}
        maxMenuHeight={150}
        placeholder={props.placeholder ? props.placeholder : placeholder ? placeholder : undefined}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option }}
      />
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export const SelectCreatable = (props: SelectPlainProps) => {
  const { options, name, error, defaultValue } = props;

  return (
    <div className={`input-block${error ? " input-error" : ""}`}>
      <CreatableSelect
        defaultValue={defaultValue}
        name={name}
        onChange={(option: Option | Option[]) => {
          props.handleSelectChange(option);
        }}
        isSearchable={props.isSearchable}
        options={options}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        isLoading={props.isLoading}
        value={props.selectedOptions}
        className={props.className}
        classNamePrefix={props.className}
        maxMenuHeight={150}
        placeholder={props.placeholder}
      />
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

const { Option } = components;
const CustomSelectOption = (props) => (
  <Option {...props}>
    <div className="option-item">
      {props.data.icon}
      {props.data.label}
    </div>
  </Option>
);
