import {
    SET_LOADING,
    SET_TYPE_OF_QUESTION,
    SET_QUESTION_TYPES,
    SET_ANSWER,
    DELETE_ANSWER,
    SET_STATE,
} from "./constants";

export const QUESTION_TYPE = {
    DROPDOWN: "Dropdown",
    MULTIPLE_CHOICE: "Multiply choices",
    CHECKBOX: "Checkbox",
    TEXT: "Text"
};

export interface AddQuestionState {
    questionType: string;
    isLoading: boolean;
    types: null | Array<string>;
}

export interface LoadingAction {
    type: typeof SET_LOADING;
    payload: boolean;
}

export interface SetQuestionTypesAction {
    type: typeof SET_QUESTION_TYPES;
    payload: Array<string>;
}

export interface SetTypeOfQuestionAction {
    type: typeof SET_TYPE_OF_QUESTION;
    payload: string;
}

export interface SetAnswerAction {
    type: typeof SET_ANSWER;
    payload: { name: string };
}

export interface DeleteAnswerAction {
    type: typeof DELETE_ANSWER;
    payload: string;
}

export interface SetStateAction {
    type: typeof SET_STATE;
    payload: AddQuestionState;
}

export type AddQuestionActionTypes =
    | LoadingAction
    | SetTypeOfQuestionAction
    | SetQuestionTypesAction
    | SetAnswerAction
    | DeleteAnswerAction
    | SetStateAction;
