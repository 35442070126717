import {
  SET_FORMAT_TYPES,
  SET_LOADING_TYPE,
  REMOVE_LOADING_TYPE,
  SET_SURVEY,
  SET_ERROR,
  SET_CATEGORY_TYPES,
} from "./constants";
import {
  SetFormatTypesAction,
  FormatTypes,
  LoadingTypeAction,
  Survey,
  SetSurveyAction,
  SetSurveyErrorAction,
  SetCategoryTypesAction,
} from "./types";

export const setLoadingType = (loadingType: string): LoadingTypeAction => ({
  type: SET_LOADING_TYPE,
  payload: loadingType,
});

export const removeLoadingType = (loadingType: string): LoadingTypeAction => ({
  type: REMOVE_LOADING_TYPE,
  payload: loadingType,
});

export const setFormatTypes = (formatTypes: FormatTypes): SetFormatTypesAction => ({
  type: SET_FORMAT_TYPES,
  payload: formatTypes,
});

export const setCategoryTypes = (categoryTypes: string[]): SetCategoryTypesAction => ({
  type: SET_CATEGORY_TYPES,
  payload: categoryTypes,
});

export const setSurvey = (survey: Survey): SetSurveyAction => ({
  type: SET_SURVEY,
  payload: survey,
});

export const setError = (error: string): SetSurveyErrorAction => ({
  type: SET_ERROR,
  payload: error,
});

// end
