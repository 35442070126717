export const ROLES = {
  USER: "user",
  CUSTOMER: "customer",
};

export const SURVEYS_TYPES = {
  OPEN: "OPEN",
  DRAFT: "DRAFT",
  CLOSED: "CLOSED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
};

export const DASHBOARD_SURVEY_CONT = 2;

export const PAGINATION_LIMIT = 20;
export const PAGINATION_LIMIT_100 = 100;
