export const SET_FORMAT_TYPES = "SET_FORMAT_TYPES";
export const SET_LOADING_TYPE = "SET_LOADING_TYPE";
export const REMOVE_LOADING_TYPE = "REMOVE_LOADING_TYPE";
export const SET_SURVEY = "SET_SURVEY";
export const SET_STEP = "SET_STEP";
export const SET_SURVEY_ID = "SET_SURVEY_ID";
export const SET_ERROR = "SET_ERROR";
export const OPEN_CREATE_SURVEY_MODAL = "OPEN_CREATE_SURVEY_MODAL";
export const CLOSE_CREATE_SURVEY_MODAL = "CLOSE_CREATE_SURVEY_MODAL";
export const PUSH_QUESTION = "PUSH_QUESTION";
export const SET_QUESTION_STEP = "SET_QUESTION_STEP";
export const PULL_QUESTION = "PULL_QUESTION";
export const SET_CATEGORY_TYPES = "SET_CATEGORY_TYPES";
export const SET_SURVEY_COLLECTORS = "SET_SURVEY_COLLECTORS";

export const LOADING_TYPES = {
    FORMAT_TYPES: "format_types",
    CREATE: "create",
    LOGO: "logo_upload",
    ADD_QUESTION: "question",
    ADD_COLLECTOR: "collector",
};
