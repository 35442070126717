import { remove, cloneDeep } from "lodash";
import {
  SET_FORMAT_TYPES,
  SET_LOADING_TYPE,
  REMOVE_LOADING_TYPE,
  SET_SURVEY,
  LOADING_TYPES,
  SET_ERROR,
  SET_CATEGORY_TYPES,
} from "./constants";

// FOR USE_REDUCER
export default function reducer(state, action) {
  switch (action.type) {
    case SET_LOADING_TYPE: {
      const newState = cloneDeep(state);
      newState.loadingTypes.push(action.payload);
      return newState;
    }
    case REMOVE_LOADING_TYPE: {
      const newState = cloneDeep(state);
      remove(newState.loadingTypes, (el) => el === action.payload);
      return newState;
    }
    case SET_FORMAT_TYPES: {
      const newState = { ...state };
      newState.formatTypes = action.payload;
      return newState;
    }
    case SET_CATEGORY_TYPES: {
      const newState = { ...state };
      newState.categoryTypes = action.payload;
      return newState;
    }
    case SET_SURVEY: {
      const newState = cloneDeep(state);
      newState.survey = action.payload;
      newState.loadingTypes = remove(newState.loadingTypes, LOADING_TYPES.CREATE);
      newState.error = "";
      return newState;
    }
    case SET_ERROR: {
      const newState = cloneDeep(state);
      newState.error = action.payload;
      return newState;
    }
    default:
      console.log(action);
      throw new Error();
  }
}
