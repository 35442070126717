import { createSelector } from "reselect";
import { RootState } from "../redux/rootReducer";
import { UserType } from "../app/Auth/types";

// auth selectors
export const isAuthenticatedSelector = (state: RootState) => state.auth.isAuthenticated;
export const isAuthLoadingSelector = (state: RootState) => state.auth.isLoading;
export const authUserSelector = (state: RootState) => state.auth.user;
export const userSelector = createSelector(authUserSelector, (user: UserType) => user);

export const authSelector = createSelector(
    isAuthenticatedSelector,
    isAuthLoadingSelector,
    userSelector,
    (isAuthenticated, isLoading, user) => ({
        isAuthenticated,
        isLoading,
        user,
    })
);
// end

// create survey modal
export const createSurveyModalOpen = (state: RootState) => state.createSurvey.isOpen;
export const surveyModalQuestions = (state: RootState) => state.createSurvey.questions;
export const surveyModalStep = (state: RootState) => state.createSurvey.currentStep;
export const selectExecFromLocation = (state: RootState) => state.createSurvey.location;
export const surveyModalId = (state: RootState) => state.createSurvey.surveyId;
export const surveyModalCollector = (state: RootState) => state.createSurvey.collector;
export const collectorsSelector = (state: RootState) => state.createSurvey.collectors;
// end

// fetch surveys
export const getSurveysList = (state: RootState) => state.surveys;
// end

// notification selectors
export const notificationSelector = (state: RootState) => state.notification;
