import { ResData } from "../shared/types";

export const isEmpty = (item: any): boolean => {
    if (typeof item === "string") {
        if (item.length === 0) return true;
        if (item.length !== 0) return false;
    }
    if (!item || typeof item !== "object") return true;
    if (Array.isArray(item)) {
        return item.length > 0 ? false : true;
    }
    return !Object.keys(item).length;
};

export const isNumber = (value: any) => /^\d*$/.test(value);

export const isInteger = (value: any, acceptZero = false) => {
    if (!isNumber(value)) return false;
    if (!Number.isInteger(Number(value)) || [".", ",", " "].includes(value)) return false;
    // eslint-disable-next-line
    if (Number(value[0]) == 0 && !acceptZero) return false;
    return true;
};

export const isDecimal = (value: number) => (value % 1 !== 0 ? true : false);

export const getResData = (res: any, inCatch = false) => {
    const responseData: ResData = {
        success: false,
        result: null,
        data: null,
        error: null,
    };

    if (inCatch) {
        if (res && typeof res === "object" && isEmpty(res)) {
            responseData.error = res.toString();
        } else {
            responseData.error = res;
        }
        return responseData;
    }
    if (isEmpty(res)) return responseData;
    if (res.result === "error") {
        responseData.error = res.debugInfo || res.errorDesc;
        return responseData;
    }
    if (res.data && res.data.result === "error") {
        responseData.error = res.data.debugInfo || res.data.errorDesc;
        return responseData;
    }
    
    if (res.data && res.result) {
        responseData.success = true;
        responseData.result = res.result;
        responseData.data = res.data;
        if (res.total) {
            responseData.total = res.total;
        }
        return responseData;
    }
    if (res.data && res.data.data && res.data.result) {
        responseData.success = true;
        responseData.result = res.data.result;
        responseData.data = res.data.data;
        if (res.data.total) {
            responseData.total = res.data.total;
        }
        return responseData;
    }
    return responseData;
};

export const generateUserId = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2);
    const id = timestamp + randomString;
    return id;
};

export const getSearchParam = (key: string, query: string): null | string => {
    if (!key || typeof key !== "string" || !query || typeof query !== "string") return null;
    const strToReplace = `${key}=`;
    const idx = query.indexOf(strToReplace);
    if (idx === -1) return null;

    const value = query.slice(idx).replace(new RegExp(strToReplace), "");
    return value;
};

export const toBase64 = (file): Promise<string | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};
