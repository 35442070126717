import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles/routeSpinner.scss";

const RouteSpinner = () => {
    return (
        <div className="route-spinner">
            <CircularProgress />
        </div>
    );
};

export default RouteSpinner;
