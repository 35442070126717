import request from "../../utils/request";
import { getResData } from "../../utils/helpers";
import { QuestionType } from "./types";

export const fetchQuestions = async (collectorId: string) => {
  try {
    const res = await request.get(`/user/survey/questions/list/${collectorId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchQuestions error => ", error);
    console.log("Error Response fetchQuestions => ", error.response);
    return null;
  }
};

export const fetchQuestionsByLink = async (data: { webLinkId: string; anonymousUserUniqueId?: string }) => {
  try {
    const res = await request.post(`/survey/questions/list`, data);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchQuestionsByLink error => ", error);
    console.log("Error Response fetchQuestionsByLink => ", error.response);
    return null;
  }
};

export const fetchQuestionsLogoByLink = async (webLinkId: string) => {
  try {
    const res = await request.get(`/survey/questions/logo/${webLinkId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchQuestionsLogo error => ", error);
    console.log("Error Response fetchQuestionsLogo => ", error.response);
    return null;
  }
};

export const submitQuestion = async (data) => {
  try {
    const res = await request.post("/survey/submit", data);
    return getResData(res.data);
  } catch (error) {
    console.error("submitQuestion error => ", error);
    console.log("Error Response submitQuestion => ", error.response);
    return getResData(error.response, true);
  }
};

export const deleteQuestion = async (id: number) => {
  try {
    const res = await request.post("/user/survey/question/delete", { questionId: id });
    return getResData(res.data);
  } catch (error) {
    console.error("deleteQuestion error => ", error);
    console.log("Error Response deleteQuestion => ", error.response);
    return getResData(error.response, true);
  }
};

export const editQuestion = async (data: QuestionType) => {
  try {
    const res = await request.post("/user/survey/question/edit", data);
    return getResData(res.data);
  } catch (error) {
    console.error("editQuestion error => ", error);
    console.log("Error Response editQuestion => ", error.response);
    return getResData(error.response, true);
  }
};

export const moveQuestionRequest = async (data) => {
  try {
    const res = await request.post("/user/survey/question/move", data);
    return getResData(res.data);
  } catch (error) {
    console.error("moveQuestion error => ", error);
    console.log("Error Response moveQuestion => ", error.response);
    return getResData(error.response, true);
  }
};


export const fetchAnalyseData = async (data: { collectorId: string }) => {
  try {
    const res = await request.post("/user/question/summaries", data);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchAnalyseData error => ", error);
    console.log("Error Response fetchAnalyseData => ", error.response);
    return null;
  }
};

export const fetchAnalyseDataExcelFile = async (data: { collectorId: string }) => {
  try {
    const res = await request.getFile(`/user/participant/report/${data.collectorId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchAnalyseDataExcelFile error => ", error);
    console.log("Error Response fetchAnalyseDataExcelFile => ", error.response);
    return null;
  }
};

export const fetchReportExcelFile = async (data: { collectorId: string }) => {
  try {
    const res = await request.getFile(`/user/survey/report/${data.collectorId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchReportExcelFile error => ", error);
    console.log("Error Response fetchReportExcelFile => ", error.response);
    return null;
  }
};

export const fetchReportRespondentFile = async (data: { collectorId: string }) => {
  try {
    const res = await request.getFile(`/user/respondent/survey/report/${data.collectorId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("fetch fetchReportRespondentFile Data error => ", error);
    console.log("Error Response fetchReportRespondentFile => ", error.response);
    return null;
  }
};

export const deleteCollector = async (data: { collectorId: number }) => {
  try {
    const res = await request.post("/user/survey/collector/delete", data);
    return getResData(res.data);
  } catch (error) {
    console.error("deleteCollector error => ", error);
    console.log("Error Response deleteCollector => ", error.response);
    return null;
  }
};

export const closeCollector = async (data: { collectorId: number }) => {
  try {
    const res = await request.post("/user/survey/collector/close", data);
    return getResData(res.data);
  } catch (error) {
    console.error("closeCollector error => ", error);
    console.log("Error Response closeCollector => ", error.response);
    return null;
  }
};

export const cleanCollector = async (data: { collectorId: number }) => {
  try {
    const res = await request.post("/user/survey/collector/cleanup", data);
    return getResData(res.data);
  } catch (error) {
    console.error("cleanCollector error => ", error);
    console.log("Error Response cleanCollector => ", error.response);
    return null;
  }
};

export const getWebLink = async (collectorId: number) => {
  try {
    const res = await request.get(`/user/survey/collector/weblink/${collectorId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("getWebLink error => ", error);
    console.log("Error Response getWebLink => ", error.response);
    return null;
  }
};

export const copyPasteSurvey = async (data: { surveyId: number }) => {
  try {
    const res = await request.post(`/user/survey/copy/paste`, data);
    return getResData(res.data);
  } catch (error) {
    console.error("copyPasteSurvey error => ", error);
    console.log("Error Response copyPasteSurvey => ", error.response);
    return null;
  }
};

export const getSummary = async (collectorId: number) => {
  try {
    const res = await request.get(`/user/survey/summary/${collectorId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("getSummary error => ", error);
    console.log("Error Response getSummary => ", error.response);
    return null;
  }
};

export const getSurvey = async (surveyId: number) => {
  try {
    const res = await request.get(`/user/survey/summary/${surveyId}`);
    return getResData(res.data);
  } catch (error) {
    console.error("getSurvey error => ", error);
    console.log("Error Response getSurvey => ", error.response);
    return null;
  }
};

export const getSurveyAnswered = async (webLink) => {
  try {
    const res = await request.get(`/survey/is/answered/${webLink}`);
    return getResData(res.data);
  } catch (error) {
    console.error("getSurveyAnswered error => ", error);
    console.log("Error Response getSurveyAnswered => ", error.response);
    return null;
  }
};

export const addQuestions = async (data) => {
  try {
    const res = await request.post(`/user/survey/questions/add`, data);
    return getResData(res.data);
  } catch (error) {
    console.error("getSurveyAnswered error => ", error);
    console.log("Error Response getSurveyAnswered => ", error.response);
    return null;
  }
};

export const fetchCollectorList = async (data) => {
  try {
    const res = await request.post(`/user/survey/collector/list`, data);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchCollectorList error => ", error);
    console.log("Error Response fetchCollectorList => ", error.response);
    return null;
  }
};
