import React, { useReducer, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import reducer from "../reducer";
import { setFormatTypes, setLoadingType, removeLoadingType, setSurvey, setError, setCategoryTypes } from "../actions";
import { useLocation } from "react-router-dom";
import MainInfoSurveyComponent from "../components/MainInfoSurveyComponent";
import Modal from "../../../shared/components/Modal/Modal";
import { fetchFormatTypes, editSurvey, fetchCategoriesTypes } from "../services";
import { LOADING_TYPES } from "../constants";
import { isEmpty } from "../../../utils/helpers";
import { surveyModalId } from "../../../redux/selectors";
import "../styles/editSurvey.scss";

const initialState = {
  formatTypes: [],
  loadingTypes: [],
  categoryTypes: [],
  survey: null,
  error: "",
};

const EditSurveyInfo = (props) => {
  const [state, dispatchAction] = useReducer(reducer, initialState);

  const isModalOpen = props.isModalOpen;
  const surveyId = useSelector(surveyModalId);
  const location = useLocation();

  useEffect(() => {
    getFormatTypes();
    getCategoriesTypes();
  }, []);

  useEffect(() => {
    dispatchAction(setSurvey({ id: surveyId }));
  }, [surveyId]);

  const getFormatTypes = async () => {
    dispatchAction(setLoadingType(LOADING_TYPES.FORMAT_TYPES));
    const resData = await fetchFormatTypes();
    dispatchAction(removeLoadingType(LOADING_TYPES.FORMAT_TYPES));
    if (!isEmpty(resData) && !isEmpty(resData.data)) {
      dispatchAction(setFormatTypes(resData.data));
    }
  };
  const getCategoriesTypes = async () => {
    const resData = await fetchCategoriesTypes();
    if (!isEmpty(resData) && !isEmpty(resData.data)) {
      const categories = resData.data.map((category) => {
        return { value: category.toLowerCase(), label: category };
      });
      dispatchAction(setCategoryTypes(categories));
    }
  };

  const onEditSurveySubmit = async (values) => {
    if (state.error) dispatchAction(setError(""));
    dispatchAction(setLoadingType(LOADING_TYPES.CREATE));
    const resData = await editSurvey({ ...values, surveyId: location.state.id });
    if (!isEmpty(resData) && resData.success) {
      props.handleClose();
      props.setUpdateSurvayData(true);
    }
    if (!isEmpty(resData) && resData.error) {
      dispatchAction(setError(resData.error));
    }
    dispatchAction(removeLoadingType(LOADING_TYPES.CREATE));
  };

  return (
    <Modal
      open={isModalOpen}
      handleClose={props.handleClose}
      title={props?.title || "Edit Survey"}
      modalClassName="create-survey-modal"
      error={state.error}
      withError={true}
    >
      <MainInfoSurveyComponent
        survey={props.survey}
        handleClose={props.handleClose}
        onSubmit={onEditSurveySubmit}
        error={state.error}
        isLoading={state.loadingTypes.includes(LOADING_TYPES.CREATE)}
        categories={state.categoryTypes}
        hideActionButtons={props?.hideActionButtons}
      />
    </Modal>
  );
};
const propsAreEqual = (prevProps, nextProps) => {
  return false;
};

export default memo(EditSurveyInfo, propsAreEqual);
