import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Input from "../../../shared/components/Input/Input";
import { SelectField } from "../../../shared/components/Input/Select";
import AssignmentIcon from "@material-ui/icons/Assignment";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { ReactComponent as AutomaticBlack } from "../assets/automatic-black.svg";
import "../assets/CreateSurvey.scss";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export type CreateSurveyData = {
  name: string;
  category: string;
  format: string;
};

type Props = {
  handleClose: () => void;
  onSubmit: (data: CreateSurveyData) => void;
  error: string;
  isLoading: boolean;
  categories: string[];
  survey: any;
  hideActionButtons?: boolean;
};

const createSurveySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(5000, "Name must be at most 5000 characters")
    .trim()
    .required("Name required"),
  category: Yup.string().required("Category required"),
});

const MainInfoSurveyComponent = (props: Props) => {
  const [expireDate, setDate] = useState<Date | null>(props.survey.expireAt);

  const [additionalFields, setAdditionalFields] = useState({
    nameSupported: props.survey.nameSupported,
    msisdnSupported: props.survey.msisdnSupported,
    locationSupported: props.survey.locationSupported,
  });
  const [skipQuestions, setSkipQuestions] = useState(props.survey.skipQuestions);
  const [questionsCounter, setQuestionsCounter] = useState(props.survey.questionsCounter);
  const [hideNoAnswers, setHideNoAnswers] = useState(props.survey.hideNoAnswers);

  const handleDateChange = (date: Date | null): void => {
    setDate(date);
  };

  const onSubmit = (values) => {
    return props.onSubmit({
      ...values,
      ...additionalFields,
      skipQuestions,
      questionsCounter,
      hideNoAnswers,
      expireDate: moment(expireDate).format("yyyy-MM-DD 23:59:59"),
    });
  };

  const handleChange = (event) => {
    setAdditionalFields({
      ...additionalFields,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="create-survey-container">
      <Formik
        validateOnBlur={false}
        initialValues={{
          name: props.survey.name,
          category: props.survey.category.toLowerCase(),
          format: props.survey.format,
        }}
        validationSchema={createSurveySchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <Input
                error={errors.name}
                touched={touched.name}
                name="name"
                value={values.name}
                placeholder="Survey Name"
                type="text"
                disabled={props?.hideActionButtons}
              />
              {props?.hideActionButtons ? (
                <Input
                  error={touched.category ? errors.category : null}
                  touched={touched.category}
                  value={values.category}
                  name="category"
                  type="text"
                  disabled={props?.hideActionButtons}
                />
              ) : (
                <Field
                  component={SelectField}
                  options={props.categories}
                  handleSelectChange={(value) => {
                    setFieldValue("category", value);
                  }}
                  value={values.category}
                  name="category"
                  className="custom-select"
                  error={touched.category ? errors.category : null}
                  touched={touched.category}
                  disabled={props?.hideActionButtons}
                />
              )}
              <label htmlFor="date">Expiration date</label>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date"
                  value={expireDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  placeholder="dd/mm/yyyy"
                  required
                  disabled={props?.hideActionButtons}
                />
              </MuiPickersUtilsProvider>

              <div className="additional-fields-container">
                <FormGroup className="additional-fields">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={additionalFields.nameSupported}
                        onChange={handleChange}
                        name="nameSupported"
                        disabled={props?.hideActionButtons}
                      />
                    }
                    label="Respondent's name is required"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={additionalFields.msisdnSupported}
                        onChange={handleChange}
                        name="msisdnSupported"
                        disabled={props?.hideActionButtons}
                      />
                    }
                    label="Respondent's number is required"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={additionalFields.locationSupported}
                        onChange={handleChange}
                        name="locationSupported"
                        disabled={props?.hideActionButtons}
                      />
                    }
                    label="Respondent's location is required"
                  />
                </FormGroup>
                <FormGroup className="additional-fields">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={skipQuestions}
                        onChange={() => setSkipQuestions(!skipQuestions)}
                        name="skipQuestions"
                        disabled={props?.hideActionButtons}
                      />
                    }
                    label="Skip questions should be optional"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={questionsCounter}
                        onChange={() => setQuestionsCounter(!questionsCounter)}
                        name="questionsCounter"
                        disabled={props?.hideActionButtons}
                      />
                    }
                    label="Show questions counter"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hideNoAnswers}
                        onChange={() => setHideNoAnswers(!hideNoAnswers)}
                        name="hideNoAnswers"
                        disabled={props?.hideActionButtons}
                      />
                    }
                    label="Hide no answers questions"
                  />
                </FormGroup>
              </div>

              <h3>Survey Category</h3>

              <Field name="format">
                {({ field, form }) => (
                  <div className="format-types">
                    <div
                      className={`${field.value === "AUTOMATIC" ? "format-type active" : "format-type"}`}
                      onClick={props?.hideActionButtons ? () => {} : () => form.setFieldValue(field.name, "AUTOMATIC")}
                    >
                      <div className="format-icon">
                        <AutomaticBlack />
                      </div>
                      <div className="format-description">
                        <h4>Automatic</h4>
                        <p>Automatically scroll to the next question</p>
                      </div>
                    </div>
                    <div
                      className={`${field.value === "CLASSIC" ? "format-type active" : "format-type"}`}
                      onClick={props?.hideActionButtons ? () => {} : () => form.setFieldValue(field.name, "CLASSIC")}
                    >
                      <div className="format-icon">
                        <AssignmentIcon style={{ fontSize: 46 }} />
                      </div>
                      <div className="format-description">
                        <h4>Classic</h4>
                        <p>Show all questions on a page at once</p>
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              {!props.hideActionButtons && (
                <div className="buttons-wrapper">
                  <button type="button" className="secondary-btn" onClick={() => props.handleClose()}>
                    Cancel
                  </button>
                  <button type="submit" className="primary-btn" disabled={props.isLoading}>
                    Save
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MainInfoSurveyComponent;
