import React, { useState } from "react";
import { QuestionType } from "../types";
import QuestionComponent from "../components/QuestionComponent";
import { deleteQuestion, editQuestion, moveQuestionRequest } from "../services";

type Props = {
  questions: Array<QuestionType>;
  question: QuestionType;
  error: any;
  value: number;
  scrollToElement: (id: number) => void;
  scrollable: boolean;
  isEdit?: boolean;
  getQuestions: () => void;
  toggleModal: () => void;
  setEditedQuestion: (data: QuestionType) => void;
  setUpdateQuestions?: any;
  hideActionButtons?: boolean;
};

const Question = (props: Props) => {
  const [move, setMove] = useState<boolean>(false);
  const options = props.question.answers.map((answer) => {
    return { label: answer.answer, value: answer.id };
  });

  const deleteQuestionHandler = async (id: number) => {
    const resData = await deleteQuestion(id);
    if (resData && resData.result) {
      props.getQuestions();
      props.setUpdateQuestions && props.setUpdateQuestions(true);
    }
  };

  function removeEmptyFollowUpQuestions(questions) {
    return questions.map((question) => {
      const { followUpQuestions, ...rest } = question;

      if (followUpQuestions && followUpQuestions.length === 0) {
        return rest;
      } else if (followUpQuestions) {
        rest.followUpQuestions = removeEmptyFollowUpQuestions(followUpQuestions);
      }

      if (rest.answers) {
        rest.answers = removeEmptyFollowUpQuestions(rest.answers);
      }

      return rest;
    });
  }

  function setAnswersToNull(questions) {
    return questions.map((question) => {
      if (question.type === "TEXT") {
        return { ...question, answers: null };
      }

      if (question.followUpQuestions) {
        question.followUpQuestions = setAnswersToNull(question.followUpQuestions);
      }

      if (question.answers) {
        question.answers = setAnswersToNull(question.answers);
      }

      return question;
    });
  }

  const moveQuestion = async (movedQuestionId: number, position: string, questionId: number) => {
    const data = {
      after: true,
      sourceQuestionId: movedQuestionId,
      targetQuestionId: questionId,
    };
    if (position === "Before") {
      data.after = false;
    }
    if (position === "After") {
      data.after = true;
    }

    const resData = await moveQuestionRequest(data);

    if (resData.success && resData.result === "ok") {
      setMove(false);
      props.setUpdateQuestions && props.setUpdateQuestions(true);
      props.getQuestions();
    }

    if (resData && resData.result) {
      return resData.data;
    }
  };

  return (
    <QuestionComponent
      questions={props.questions}
      question={props.question}
      error={props.error}
      value={props.value}
      options={options}
      scrollToElement={props.scrollToElement}
      scrollable={props.scrollable}
      isEdit={props.isEdit}
      deleteQuestion={deleteQuestionHandler}
      moveQuestion={moveQuestion}
      move={move}
      setMove={setMove}
      toggleModal={props.toggleModal}
      setEditedQuestion={props.setEditedQuestion}
      hideActionButtons={props?.hideActionButtons}
    />
  );
};

export default Question;
