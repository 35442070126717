import request from "../../../../utils/request";
import { getResData } from "../../../../utils/helpers";
import { ResData, ThunkD, ThunkR } from "../../../../shared/types";
import { ContactType } from "../../../Contacts/types";
import { COLLECTOR_TYPES } from "./constants";
import { AddCollectorData } from "./AddCollector";
import { Collector } from "../../types";
import { setSurveyCollectors } from "../../actions";

interface ICollectorTypesRes extends ResData {
    data: string[];
}

interface IContactsRes extends ResData {
    data: ContactType[];
}

interface IAddCollectorRes extends ResData {
    data: { collectorId: number; webLink?: string };
}

interface ICollectorListRes extends ResData {
    data: Array<Collector>;
}

export const fetchCollectorTypes = async (): Promise<ICollectorTypesRes> => {
    try {
        const res = await request.get("/user/survey/collector/types");
        return getResData(res.data);
    } catch (error) {
        console.error("fetchCollectorTypes error => ", error);
        console.log("Error Response fetchCollectorTypes => ", error.response);
        return getResData(error.response, true);
    }
};

export const fetchContacts = async (): Promise<IContactsRes> => {
    try {
        const res = await request.post("/user/contact/list");
        return getResData(res.data);
    } catch (error) {
        console.error("fetchContacts error => ", error);
        console.log("Error Response fetchContacts => ", error.response);
        return getResData(error.response, true);
    }
};

export const addContacts = async (data: {
    collectorId: number;
    participants: Array<string>;
    selectAll: boolean;
}): Promise<ResData> => {
    try {
        const res = await request.post("/user/survey/collector/sms/participant/add", data);
        return getResData(res.data);
    } catch (error) {
        console.error("addContacts error => ", error);
        console.log("Error Response addContacts => ", error.response);
        return getResData(error.response, true);
    }
};

export const addContactFile = async (data: {
    collectorId: number;
    fileId: number;
}): Promise<ResData> => {
    try {
        const res = await request.post("/user/survey/collector/sms/participant/file/add", data);
        return getResData(res.data);
    } catch (error) {
        console.error("addContactFile error => ", error);
        console.log("Error Response addContacts => ", error.response);
        return getResData(error.response, true);
    }
};

export const addSurveyCollector = async (collectorData: AddCollectorData): Promise<IAddCollectorRes> => {
    try {
        const collector = { ...collectorData };
        if (collectorData.collector === COLLECTOR_TYPES[0].id) {
            delete collector.contacts;
            delete collector.selectAll;
            delete collector.fileId;
        }
        const addCollectorRes = await request.post("/user/survey/collector/add", collector);
        const addCollectorResData = getResData(addCollectorRes.data) as IAddCollectorRes;
        if (
            addCollectorResData.success &&
            addCollectorResData.data &&
            collectorData.collector === COLLECTOR_TYPES[0].id
        ) {
            if (collectorData.fileId) { // if fileId exists, then type this file upload
                await addContactFile({
                    collectorId: addCollectorResData.data.collectorId,
                    fileId: collectorData.fileId
                });
            } else {
                await addContacts({
                    collectorId: addCollectorResData.data.collectorId,
                    participants: collectorData.contacts,
                    selectAll: collectorData.selectAll,
                });
            }
            return getResData(addCollectorResData);
        }
        return addCollectorResData;
    } catch (error) {
        console.error("addSurveyCollector error => ", error);
        console.log("Error Response addSurveyCollector => ", error.response);
        return getResData(error.response, true);
    }
};

type FetchCollectors = {
    surveyId: number;
    length?: number;
    start?: number;
};

export const fetchCollectorList = (data: FetchCollectors): ThunkR<Promise<ICollectorListRes>> => async (
    dispatch: ThunkD
) => {
    try {
        const res = await request.post("/user/survey/collector/list", data);
        const resData = getResData(res.data);
        if (resData.result && resData.data) {
            dispatch(setSurveyCollectors(resData.data));
        }
        return resData;
    } catch (error) {
        console.error("fetchCollectorList error => ", error);
        console.log("Error Response fetchCollectorList => ", error.response);
        return getResData(error.response, true);
    }
};


export const getSummary = async (surveyId) => {
    try {
        const res = await request.get(`/user/survey/summary/${surveyId}`);
        return getResData(res.data);
    } catch (error) {
        console.error("getSummary error => ", error);
        console.log("Error Response getSummary => ", error.response);
        return null;
    }
};

export const submitSurveyForReview = async (data: {
    surveyId: number;
}): Promise<ResData> => {
    try {
        const res = await request.post("/user/survey/submit/for/review", data);
        return getResData(res.data);
    } catch (error) {
        console.error("addContacts error => ", error);
        console.log("Error Response addContacts => ", error.response);
        return getResData(error.response, true);
    }
};
