import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import _capitalize from "lodash/capitalize";
import { SvgIcon } from "@material-ui/core";
import WebLink from "./WebLink";
import Input from "../../../../shared/components/Input/Input";
import ContactsSelector, { IOnSubmit } from "../../../Survey/components/Collector/ContactsSelector";
import ContactsFileSelector, { IOnFileSubmit } from "../../../Survey/components/Collector/ContactsFileSelector";
import { isEmpty } from "../../../../utils/helpers";
import { Survey } from "../../types";
import { COLLECTOR_TYPES } from "./constants";
import {
  fetchCollectorTypes,
  addSurveyCollector,
  fetchCollectorList,
  getSummary,
  submitSurveyForReview,
} from "./services";
import "./addCollector.scss";
import { showNotification } from "../../../../shared/components/Notification/actions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";

export type AddCollectorData = {
  name: string;
  collector: string;
  surveyId: number;
  contacts: string[];
  selectAll?: boolean;
  fileId?: number;
};

type Props = {
  handleClose: () => void;
  survey: Survey;
  error: string;
  refetchCollectors?: boolean;
  executedFrom?: string;
  surveyCreation?: boolean;
  getSurveys?: any;
};

const addCollectorSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Name must be at least 1 character")
    .max(5000, "Name must be at most 5000 characters")
    .trim()
    .required("Name required"),
});

const AddCollectorComponent = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [types, setTypes] = useState<string[]>([]);
  const [isFromFile, setIsFromFile] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ general: string; select: string }>({ general: "", select: "" });
  const [webLinkData, setWebLinkData] = useState<{ show: boolean; showSurveySubmit?: boolean; link?: string }>({
    show: false,
    showSurveySubmit: false,
    link: "",
  });

  const addContactsRef = useRef<{ onSubmit: () => IOnSubmit }>();
  const addContactsFileRef = useRef<{ onSubmit: () => IOnFileSubmit }>();
  const dispatch = useDispatch();

  useEffect(() => {
    getCollectorTypes();
  }, []);

  const toggleContactsType = (): void => {
    setIsFromFile(!isFromFile);
  };

  const getCollectorTypes = async () => {
    setLoading(true);
    const resData = await fetchCollectorTypes();

    if (resData && resData.success) {
      setTypes(resData.data);
    }
    setLoading(false);
  };

  const onSubmit = async (values: { name: string; collector: string }) => {
    const result: {
      collector: string;
      name: string;
      surveyId: number;
      contacts: Array<string>;
      selectAll?: boolean;
      fileId?: number;
    } = {
      collector: values.collector,
      name: values.name,
      surveyId: props.survey.id,
      contacts: [],
      fileId: null,
    };

    if (errors.general) {
      setErrors({ ...errors, general: "" });
    }

    if (result.collector === COLLECTOR_TYPES[0].id) {
      if (!isFromFile) {
        const submitResult = addContactsRef.current.onSubmit();
        if (submitResult.error) {
          console.log(submitResult.error);
          return null;
        }
        if (submitResult.result) {
          result.contacts = submitResult.result.participants;
          result.selectAll = submitResult.result.selectAll;
        }
      } else {
        const submitResult = addContactsFileRef.current.onSubmit();
        if (submitResult.error) {
          console.log(submitResult.error);
          return null;
        }
        if (submitResult.result) {
          result.fileId = submitResult.result.file;
        }
      }
    }

    setLoading(true);
    const resData = await addSurveyCollector(result);
    if (!resData.success && resData.error) {
      setLoading(false);
      if (props.executedFrom) {
        dispatch(showNotification({ message: `Failed to create ${props.executedFrom}`, variant: "error" }));
      } else {
        dispatch(showNotification({ message: "Failed to create survey", variant: "error" }));
      }
      return setErrors({ ...errors, general: resData.error });
    }
    if (props.executedFrom) {
      dispatch(
        showNotification({
          message: `${_capitalize(props.executedFrom)} was create successfully`,
          variant: "success",
        })
      );
    } else {
      dispatch(showNotification({ message: "Survey was create successfully", variant: "success" }));
    }

    await dispatch(fetchCollectorList({ surveyId: props.survey.id }));

    const surveySummary = await getSummary(props.survey.id);
    if (surveySummary.success) {
      surveySummary.data.status === "OPEN"
        ? setWebLinkData({ show: true, link: resData.data.webLink })
        : setWebLinkData({ show: true, showSurveySubmit: true });
    }
    setLoading(false);
  };

  const submitForReview = async () => {
    setLoading(true);
    await submitSurveyForReview({ surveyId: props.survey.id });
    await props.getSurveys();
    props.handleClose();
    setLoading(false);
  };

  const renderButtonsGroup = () => (
    <div className="add-collector-buttons-wrapper">
      <button type="button" className="secondary-btn" onClick={props.handleClose}>
        Close
      </button>
      <button className="primary-btn" onClick={submitForReview}>
        Submit For Review
      </button>
    </div>
  );

  return (
    <div className="add-survey-collector-container">
      {webLinkData.show ? (
        webLinkData.showSurveySubmit ? (
          renderButtonsGroup()
        ) : (
          <WebLink link={webLinkData.link} handleClose={props.handleClose} />
        )
      ) : (
        <Formik
          validateOnBlur={false}
          initialValues={{
            name: "",
            collector: COLLECTOR_TYPES[0].id,
          }}
          validationSchema={addCollectorSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors: formErrors, touched }) => {
            return (
              <Form>
                <Input
                  error={formErrors.name}
                  touched={touched.name}
                  name="name"
                  value={values.name}
                  placeholder="Collector Name"
                  type="text"
                />

                <h3>Collector type</h3>

                {!isEmpty(types) && (
                  <Field name="collector">
                    {({ field, form }) => (
                      <div className="collector-types">
                        {types.map((collector) => {
                          const mappedCollector = COLLECTOR_TYPES.find((c) => c.id === collector);
                          return (
                            <div
                              key={collector}
                              className={`${
                                field.value === mappedCollector.id ? "collector-type active" : "collector-type"
                              }`}
                              onClick={() => form.setFieldValue(field.name, mappedCollector.id)}
                            >
                              <SvgIcon component={mappedCollector.icon} className="collector-icon" />
                              <div className="collector-description">
                                <h4>{mappedCollector.title}</h4>
                                <p>{mappedCollector.description}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Field>
                )}

                {values.collector === COLLECTOR_TYPES[0].id && (
                  <div className="add-contacts-container">
                    <h3 className="mb-0">Add Your Contacts Details</h3>
                    <p className="mt-0 mb-0 sub-title-small">(This details use when you share your survey.)</p>
                    <div className="contact-switcher">
                      <span className="left-part">Manual</span>
                      <FormControlLabel
                        control={<GreySwitch checked={isFromFile} onChange={toggleContactsType} />}
                        label=""
                      />
                      <span>From file</span>
                    </div>
                    {!isFromFile && <ContactsSelector ref={addContactsRef} />}
                    {isFromFile && <ContactsFileSelector ref={addContactsFileRef} />}
                  </div>
                )}

                <div className="add-collector-buttons-wrapper mt-26px">
                  <button type="button" className="secondary-btn" onClick={props.handleClose}>
                    Cancel
                  </button>
                  <button type="submit" className="primary-btn" disabled={isLoading}>
                    Add Collector
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      {errors.general && <p className="error-text">{errors.general}</p>}
    </div>
  );
};

export default AddCollectorComponent;

const GreySwitch = withStyles({
  switchBase: {
    color: grey[300],
    "&$checked": {
      color: grey[300],
    },
    "&$checked + $track": {
      backgroundColor: "#000",
    },
  },
  checked: {},
  track: {},
})(Switch);
