import { deleteToken, getToken } from "./localStorage";

const responseInterceptor = (response: { data: any; response: any }) => {
  const result = {
    response: null,
    data: null,
  };

  if (!response) return result;
  if (response.data && response.data.errorCode === 107) {
    console.log("responseInterceptor response.data", response);
    deleteToken();
    window.location.reload();
    return result;
  }
  return response;
};

const parseJSON = async (response: any) => {
  const result = {
    response: response,
    data: null,
  };
  try {
    if (response.status === 204 || response.status === 205) {
      return result;
    }
    if (response.ok) {
      const data = await response.json();
      result.data = data;
      return responseInterceptor(result);
    }
    return response;
  } catch (error) {
    return {
      response: response,
      data: null,
    };
  }
};

const parseBlob = async (response: any) => {
  const result = {
    response: response,
    data: null,
  };
  try {
    if (response.status === 204 || response.status === 205) {
      return result;
    }
    if (response.ok) {
      const response_copy = response.clone();
      try {
        const dataJSON = await response.json();
        result.data = dataJSON;
      } catch (e) {
        const dataBLOB = await response_copy.blob();
        result.data = {
          data: dataBLOB,
          result: "ok",
        };
      }

      return responseInterceptor(result);
    }
    return response;
  } catch (error) {
    return {
      response: response,
      data: null,
    };
  }
};

const handleError = (response: any) => {
  let errorText = "";
  switch (true) {
    case !response: {
      errorText = "Unexpected error occurred";
      break;
    }

    case response.statusText: {
      errorText = response.statusText;
      break;
    }
    case !response.statusText && response.status === 404: {
      errorText = "Not Found";
      break;
    }

    case !response.statusText && response.status >= 500: {
      errorText = "Internal server error";
      break;
    }

    default:
      errorText = "Unexpected error occurred";
      break;
  }
  const error = new Error(errorText) as any;
  error.response = response;
  throw error;
};

function checkStatus(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return parseJSON(response);
  }
  handleError(response);
}

function checkStatusAndParseFile(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return parseBlob(response);
  }
  handleError(response);
}

const config = (window as any).globalConfig;

class Request {
  defaultOptions: {
    headers: {
      Authorization?: string;
    };
  };
  constructor() {
    this.defaultOptions = {
      headers: {},
    };
  }

  public setAuthorizationToken(token: string | null) {
    if (token) {
      this.defaultOptions.headers.Authorization = `Bearer ${token}`;
    }
  }

  public deleteAuthorizationToken() {
    this.defaultOptions.headers = {};
    deleteToken();
  }

  private getDefaultHeaders(type: string) {
    if (["POST", "PUT"].includes(type)) {
      return {
        ...this.defaultOptions.headers,
        "Content-Type": "application/json",
      };
    }
  }

  private checkOldToken() {
    const token = getToken();
    const originalToken = this.defaultOptions.headers.Authorization;
    if (!originalToken && !token) return; // this is the sing in process
    if ((token && originalToken && originalToken !== `Bearer ${token}`) || !token) {
      this.deleteAuthorizationToken();
      console.log("checkOldToken originalToken, token", originalToken, token);
      return window.location.reload();
    }
  }

  private normalizeUrl(url: string) {
    if (!url || url.length === 0) return url;
    return url[0] === "/" ? url.slice(1) : url;
  }

  // HTTP methods
  get(url: string, options: any = {}) {
    this.checkOldToken();
    url = this.normalizeUrl(url);
    return fetch(`${config.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
      ...options,
      ...this.defaultOptions,
      method: "GET",
    })
      .then(checkStatus)
      .catch(handleError);
  }
  getFile(url: string, options: any = {}) {
    this.checkOldToken();
    url = this.normalizeUrl(url);
    return fetch(`${config.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
      ...options,
      ...this.defaultOptions,
      method: "GET",
    })
      .then(checkStatusAndParseFile)
      .catch(handleError);
  }
  post(url: string, data: any = {}, options: any = {}) {
    this.checkOldToken();
    url = this.normalizeUrl(url);
    return fetch(`${config.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
      body: JSON.stringify(data),
      ...options,
      headers: {
        ...this.getDefaultHeaders("POST"),
        ...options.headers,
      },
      method: "POST",
    })
      .then(checkStatus)
      .catch(handleError);
  }
  put(url: string, options: any = {}) {
    this.checkOldToken();
    url = this.normalizeUrl(url);
    return fetch(`${config.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
      ...options,
      headers: {
        ...this.getDefaultHeaders("PUT"),
        ...options.headers,
      },
      method: "PUT",
    })
      .then(checkStatus)
      .catch(handleError);
  }
  delete(url: string, options: any = {}) {
    this.checkOldToken();
    url = this.normalizeUrl(url);
    return fetch(`${config.serverUrl || process.env.REACT_APP_SERVER_URL}/${url}`, {
      ...options,
      ...this.defaultOptions,
      method: "DELETE",
    })
      .then(checkStatus)
      .catch(handleError);
  }
}

const request = new Request();

export default request;
