// @flow
import { SHOW_NOTIFICATION, HIDE_NOTIFICATION, Variants, ShowDatatype } from "./constants";

type State = {
    show: boolean,
    message: string,
    variant: Variants
};
const initialState: State = {
    show: false,
    variant: "",
    message: ""
};

export default (
    state: State = initialState,
    action: {
        type: string,
        payload: ShowDatatype
    }
): State => {
    switch (action.type) {
        case SHOW_NOTIFICATION: {
            return { message: action.payload.message, variant: action.payload.variant, show: true };
        }
        case HIDE_NOTIFICATION: {
            return { message: "", variant: state.variant, show: false };
        }
        default:
            return state;
    }
};
