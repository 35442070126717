import { SHOW_NOTIFICATION, HIDE_NOTIFICATION, ShowDatatype } from "./constants";

export const showNotification = (data: ShowDatatype) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: data,
    };
};

export const hideNotification = () => ({
    type: HIDE_NOTIFICATION,
});
