import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import request from "../../utils/request";
import { setAuthLoading, setAuthState } from "./actions";
import { getResData } from "../../utils/helpers";
// import { saveToken } from "../../utils/localStorage";
import { SingUpSubmitData, SignInSubmitData, ForgotPasswordSubmitData, ResetPasswordSubmitData } from "./constants";
import { ThunkD, ThunkR } from "../../shared/types";
import { ResData } from "../../shared/types";
import { saveToken, getToken } from "../../utils/localStorage";
// import { RootState } from "../../redux/rootReducer";

// export const fetchUser = () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getStore: () => RootState) => {
export const fetchUser = () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
        dispatch(setAuthLoading(true));
        const res = await request.get("/user/details");
        const resData = getResData(res.data);
        if (!resData.success) {
            request.deleteAuthorizationToken();
            dispatch(
                setAuthState({
                    isAuthenticated: false,
                    isLoading: false,
                    user: {},
                })
            );
            return resData;
        }
        dispatch(
            setAuthState({
                isAuthenticated: true,
                isLoading: false,
                user: resData.data ? resData.data : {},
            })
        );
        return resData;
    } catch (error) {
        console.error("fetchUser error => ", error);
        return null;
    }
};

export const logOutUser = (): ThunkR<Promise<ResData>> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
        const res = await request.post("/user/logout");
        const resData = getResData(res.data);
        if (resData.success) {
            request.deleteAuthorizationToken();
            dispatch(
                setAuthState({
                    isAuthenticated: false,
                    isLoading: false,
                    user: {},
                })
            );
        }
        return resData;
    } catch (error) {
        console.error("logOutUser error => ", error);
        console.log("Error Response logOutUser => ", error.response);
        return getResData(error, true);
    }
};

export const getProfile = () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
        const res = await request.get("/api/v1/account/profile");
        console.log(res);
    } catch (error) {
        console.error("getProfile error => ", error);
        return null;
    }
};

export const signIn = async (loginData: SignInSubmitData) => {
    try {
        const res = await request.post("/login", loginData);
        return getResData(res.data);
    } catch (error) {
        console.error("signIn error => ", error);
        console.log("Error Response signIn => ", error.response);
        return null;
    }
};

export const signInConfirm = (submitData: { code: string; msisdn: string; token: string }): ThunkR<Promise<ResData>> => async (
    dispatch: ThunkD
) => {
    try {
        const options = {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };
        const res = await request.post("/login/confirm", {code: submitData.code}, options);
        const resData = getResData(res.data);
        if (resData.success && resData.result) {
            if (typeof resData.data == "string") {
                request.setAuthorizationToken(resData.data);
                saveToken(resData.data);
            }
            await dispatch(fetchUser());
        }
        return resData;
    } catch (error) {
        console.error("signInConfirm error => ", error);
        console.log("Error Response signInConfirm => ", error.response);
        return getResData(error, true);
    }
};

// export const loginUserConfirm = (email: string, password: string) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//     try {
//         dispatch(setAuthLoading(true));
//         const data = await request.post("/posts", { email, password });
//         if (!isEmpty(data)) {
//             request.setAuthorizationToken("xpCCuY349dm6&%3$$mscZoiGG");
//             saveToken("xpCCuY349dm6&%3$$mscZoiGG", data);
//             dispatch(fetchUser());
//         }
//         return data;
//     } catch (error) {
//         dispatch(setAuthLoading(false));
//         console.error("loginUser error => ", error);
//         console.log("Error Response => ", error.response);
//         return null;
//     }
// };

export const signUp = async (submitData: SingUpSubmitData) => {
    try {
        const res = await request.post("/sign-up", submitData);
        return getResData(res.data);
    } catch (error) {
        console.error("signUp error => ", error);
        console.log("Error Response signUp => ", error.response);
        return null;
    }
};

export const signUpConfirm = (submitData: { code: string; msisdn: string; token: string }): ThunkR<Promise<ResData>> => async (
    dispatch: ThunkD
) => {
    try {
        const options = {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };
        const res = await request.post("/sign-up/confirm", {code: submitData.code}, options);
        const resData = getResData(res.data);
        if (resData.success && resData.result) {
            if (typeof resData.data == "string") {
                request.setAuthorizationToken(resData.data);
                saveToken(resData.data);
            }
            await dispatch(fetchUser());
        }
        return resData;
    } catch (error) {
        console.error("signUpConfirm error => ", error);
        console.log("Error Response signUpConfirm => ", error.response);
        return getResData(error, true);
    }
};

export const forgotPassword = async (submitData: ForgotPasswordSubmitData) => {
    try {
        const res = await request.post("/password/forgot", submitData);
        return getResData(res.data);
    } catch (error) {
        console.error("forgotPassword error => ", error);
        console.log("Error Response forgotPassword => ", error.response);
        return null;
    }
};

export const forgotPasswordConfirm = async (submitData: ForgotPasswordSubmitData) => {
    try {
        const res = await request.post("/password/forgot/otp/confirm", submitData);
        const resData = getResData(res.data);
        if (resData.success && resData.result) {
            if (typeof resData.data == "string") {
                request.setAuthorizationToken(resData.data);
                saveToken(resData.data);
            }
        }
        return resData;
    } catch (error) {
        console.error("forgotPassword error => ", error);
        console.log("Error Response forgotPassword => ", error.response);
        return null;
    }
};

export const resetPassword = async (submitData: ResetPasswordSubmitData) => {
    try {
        const options = {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };
        const res = await request.post("/user/password/reset", submitData, options);
        return getResData(res.data);
    } catch (error) {
        console.error("resetPassword error => ", error);
        console.log("Error Response resetPassword => ", error.response);
        return null;
    }
};

export const resendOtp = async () => {
    try {
        const options = {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };
        const res = await request.post("/otp/resend", {}, options);
        const resData = getResData(res.data);
        return resData;
    } catch (error) {
        console.error("resendOtp error => ", error);
        console.log("Error Response resendOtp => ", error.response);
        return getResData(error, true);
    }
};