import React from "react";
import { QuestionType } from "../types";
import { SelectField } from "../../../shared/components/Input/Select";
import MultipleChoice from "../../../shared/components/Input/MultipleChoice";
import Checkbox from "../../../shared/components/Input/Checkbox";
import { Field } from "formik";
import { isEmpty } from "../../../utils/helpers";
import { QUESTION_TYPE } from "../constants";
import EditControls from "./EditControls";
import "./QuestionComponent.scss";

type Props = {
  questions: Array<QuestionType>;
  question: QuestionType;
  error: string;
  value: number;
  options: Array<{ label: string; value: number }>;
  scrollToElement: (id: number) => void;
  scrollable: boolean;
  isEdit: boolean;
  deleteQuestion: (id: number) => void;
  moveQuestion: (movedQuestionId: number, position: string, questionId: number) => void;
  move: boolean;
  setMove: (move: boolean) => void;
  toggleModal: () => void;
  setEditedQuestion: (data: QuestionType) => void;
  hideActionButtons?: boolean;
};

const QuestionComponent = (props: Props) => {
  const renderComponent = () => {
    if (props.question.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
      return (
        <Field
          name={`question-${props.question.id}`}
          id={props.question.id}
          options={props.options}
          className="custom-radio"
          component={MultipleChoice}
          value={Number(props.value)}
          error={props.error && !props.value ? true : false}
          scrollToElement={props.scrollToElement}
          scrollable={props.scrollable}
        />
      );
    } else if (props.question.type === QUESTION_TYPE.DROPDOWN) {
      return (
        <Field
          name={`question-${props.question.id}`}
          id={props.question.id}
          options={props.options}
          className="custom-select"
          component={SelectField}
          value={props.value}
          error={props.error && !props.value ? true : false}
          scrollToElement={props.scrollToElement}
          scrollable={props.scrollable}
        />
      );
    } else if (props.question.type === QUESTION_TYPE.CHECKBOX) {
      return (
        <Field
          name={`question-${props.question.id}`}
          id={props.question.id}
          options={props.options}
          className="custom-checkbox"
          component={Checkbox}
          value={props.value}
          error={props.error && isEmpty(props.value) ? true : false}
          scrollToElement={props.scrollToElement}
          scrollable={props.scrollable}
        />
      );
    } else if (props.question.type === QUESTION_TYPE.TEXT) {
      return (
        <>
          <Field
            name={`question-${props.question.id}`}
            id={props.question.id}
            className="custom-textarea"
            as="textarea"
            value={props.value}
            error={props.error && !props.value ? true : false}
            scrollToElement={props.scrollToElement}
            scrollable={props.scrollable}
            maxLength={200}
            rows={4}
          />
          {props?.error && <p className="error-text">{props?.error}</p>}
        </>
      );
    }
  };
  return (
    <div className="question-item">
      <h3>{props.question.question}</h3>
      {props.isEdit && (
        <EditControls
          questions={props.questions}
          question={props.question}
          deleteQuestion={props.deleteQuestion}
          moveQuestion={props.moveQuestion}
          move={props.move}
          setMove={props.setMove}
          toggleModal={props.toggleModal}
          setEditedQuestion={props.setEditedQuestion}
          hideActionButtons={props?.hideActionButtons}
        />
      )}
      {renderComponent()}
    </div>
  );
};

export default QuestionComponent;
