import request from "../../utils/request";
import { getResData } from "../../utils/helpers";
import { FormatTypes } from "./types";
import { CreateSurveyData } from "./components/CreateSurvey";
import { AddQuestionData } from "./containers/AddQuestion/AddQuestionComponent";

export const fetchFormatTypes = async (): Promise<{ data: FormatTypes }> => {
    try {
        const res = await request.get("/user/survey/format/types");
        return getResData(res.data);
    } catch (error) {
        console.error("fetchFormatTypes error => ", error);
        console.log("Error Response fetchFormatTypes => ", error.response);
        return getResData(error.response, true);
    }
};


export const fetchCategoriesTypes = async () => {
    try {
        const res = await request.get("/user/survey/category/types");
        return getResData(res.data);
    } catch (error) {
        console.error("fetchCategoriesTypes error => ", error);
        console.log("Error Response fetchCategoriesTypes => ", error.response);
        return getResData(error.response, true);
    }
};

export const createSurvey = async (survey: CreateSurveyData) => {
    try {
        const res = await request.post("/user/survey/add", survey);
        return getResData(res.data);
    } catch (error) {
        console.error("createSurvey error => ", error);
        console.log("Error Response createSurvey => ", error.response);
        return getResData(error.response, true);
    }
};

export const uploadSurveyLogo = async (surveyId: number, logo: string) => {
    try {
        const res = await request.post("/user/survey/logo/upload", { surveyId, logo });
        return getResData(res.data);
    } catch (error) {
        console.error("uploadSurveyLogo error => ", error);
        console.log("Error Response uploadSurveyLogo => ", error.response);
        return getResData(error.response, true);
    }
};

export const fetchQuestionTypes = async (): Promise<{ data: Array<string> }> => {
    try {
        const res = await request.get("/user/survey/question/type/get");
        return getResData(res.data);
    } catch (error) {
        console.error("fetchQuestionTypes error => ", error);
        console.log("Error Response fetchQuestionTypes => ", error.response);
        return getResData(error.response, true);
    }
};

export const addSurveyQuestions = async (data: AddQuestionData[]) => {
    try {
        const res = await request.post("/user/survey/questions/add", data);
        return getResData(res.data);
    } catch (error) {
        console.error("addSurveyQuestions error => ", error);
        console.log("Error Response addSurveyQuestions => ", error.response);
        return getResData(error.response, true);
    }
};
