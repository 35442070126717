import { CLEAR_SURVEYS, SET_SURVEYS } from "./constants";
import { Surveys, SetSurveysAction, ClearSurveysAction } from "./types";

const initialState: Surveys = [];

export const setSurveysReducer = (
    state: Surveys = initialState,
    action: SetSurveysAction | ClearSurveysAction
): Surveys => {
    switch (action.type) {
        case SET_SURVEYS: {
            return (state = action.payload);
        }
        case CLEAR_SURVEYS: {
            state = [];
            return state;
        }
        default:
            return state;
    }
};
