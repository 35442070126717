import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditSurveyComponent from "../components/EditSurveyComponent";
import {
  fetchQuestions,
  fetchQuestionsByLink,
  submitQuestion,
  fetchQuestionsLogoByLink,
  getSurveyAnswered,
} from "../services";
import { forEach } from "lodash";
import * as Yup from "yup";
// eslint-disable-next-line
import { scroller } from "react-scroll";
import { generateUserId, getSearchParam } from "../../../utils/helpers";
import { showNotification } from "../../../shared/components/Notification/actions";
import { getUserId } from "../../../utils/localStorage";
import { AdditionalFields, QuestionType } from "../types";
import { editQuestion } from "../services";

const EditSurvey = (props) => {
  const [questions, setQuestions] = useState<Array<QuestionType>>([]);
  const [canSkipQuestions, setCanSkipQuestions] = useState<boolean>(false);
  const [additionalFields, setAdditionalFields] = useState<AdditionalFields>({});
  const [format, setFormat] = useState<string>();
  const [initialValues, setInitialValues] = useState({});
  const [questionSchema, setQuestionSchema] = useState({});
  const [answered, setAnswered] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [editedQuestion, setEditedQuestion] = useState<QuestionType>();
  const [loadingTypes] = useState<string[]>([]);
  const [imgData, setImgData] = useState<string>();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const survey = {
    id: props.location.state ? props.location.state.id : null,
    name: props.location.state ? props.location.state.name : null,
    isPreview: props.location.state ? props.location.state.isPreview : null,
    isEdit: props.location.state ? props.location.state.isEdit : null,
  };

  useEffect(() => {
    setFormik();
  }, [questions]);

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    const _webLink = getSearchParam("webLink", location.search);
    if (!_webLink && !survey.isPreview && !survey.isEdit) {
      return history.push(`${process.env.PUBLIC_URL}/my-surveys`);
    }
    if (survey.isPreview || survey.isEdit) {
      const resData = await fetchQuestions(survey.id);
      if (resData && resData.result) {
        setFormat(resData.data.format);
        return setQuestions(resData.data.questions);
      }
    }

    if (_webLink) {
      const imgRes = await fetchQuestionsLogoByLink(_webLink);
      setImgData(imgRes?.data);
      const userId = getUserId(_webLink);
      const data = {
        webLinkId: _webLink,
        anonymousUserUniqueId: userId,
      };
      const resDataAnswered = await getSurveyAnswered(_webLink);
      if (resDataAnswered.data) {
        dispatch(showNotification({ message: "You already answered the survey", variant: "error" }));
        return;
      }
      const resData = await fetchQuestionsByLink(data);
      if (resData && resData.result) {
        setFormat(resData.data.format);
        setCanSkipQuestions(resData.data.skipQuestions);
        setAdditionalFields({
          nameSupported: resData.data.nameSupported,
          msisdnSupported: resData.data.msisdnSupported,
          locationSupported: resData.data.locationSupported,
          questionsCounter: resData.data.questionsCounter,
        });
        return setQuestions(resData.data.questions);
      } else {
        setError(resData.error);
      }
    }
  };

  const setFormik = () => {
    const initialValues = {};
    const questionSchema = {};
    forEach(questions, function (value) {
      initialValues["question-" + value.id] = "";
      questionSchema["question-" + value.id] = canSkipQuestions
        ? Yup.string().nullable()
        : Yup.string().required("Please answer the question");
    });
    setInitialValues(initialValues);
    setQuestionSchema(questionSchema);
  };

  const scrollToElement = (id) => {
    let currentIndex = questions.findIndex((question) => question.id === id);
    if (currentIndex < questions.length - 1) {
      let nextId = questions[currentIndex + 1].id;
      scroller.scrollTo(`question-${nextId}`, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -100,
      });
    }
  };

  const submit = async (values: any) => {
    if (survey.isPreview || survey.isEdit || answered) {
      history.push(`${process.env.PUBLIC_URL}/my-surveys`);
    } else {
      for (const property in values) {
        if (!values[property]) {
          delete values[property];
        }
      }
      submitQuestions(values);
    }
  };

  const backToSurveys = () => {
    history.push(`${process.env.PUBLIC_URL}/my-surveys`);
  };

  const submitQuestions = async (values) => {
    let questionsSubmit = [];
    let otherFields = [];
    for (const [key, value] of Object.entries(values)) {
      if (key.includes("question")) {
        questionsSubmit.push({
          questionId: key.replace("question-", ""),
          answers: Array.isArray(value) ? value : Number(value) ? [Number(value)] : null,
          textAnswer: Array.isArray(value) ? null : Number(value) ? null : value,
        });
      } else {
        let formattedStr = value;
        if (key === "msisdn") {
          formattedStr = value.toString().replace("+", "");
        }
        otherFields[key] = formattedStr;
      }
    }

    const userId = generateUserId();
    const _webLink = getSearchParam("webLink", location.search);
    const data = {
      anonymousUserUniqueId: userId,
      webLinkId: _webLink,
      questions: questionsSubmit,
      ...otherFields,
    };
    const resData = await submitQuestion(data);

    if (resData && resData.result) {
      setAnswered(true);
      setSubmitted(true);
    } else if (resData && resData.error) {
      setError(resData.error);
    }
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const onAddQuestionSubmit = async (data: QuestionType) => {
    const questionData = {
      ...data.questions[0],
      questionId: editedQuestion.id,
    };
    const resData = await editQuestion(questionData);
    if (resData && resData.result) {
      toggleModal();
      return getQuestions();
    } else if (resData && resData.error) {
      dispatch(showNotification({ message: resData.error, variant: "error" }));
    }
  };

  return (
    <EditSurveyComponent
      id={survey.id}
      questions={questions}
      additionalFields={additionalFields}
      name={survey.name}
      scrollable={format === "AUTOMATIC"}
      onSubmit={submit}
      backToSurveys={backToSurveys}
      initialValues={initialValues}
      canSkipQuestions={canSkipQuestions}
      questionSchema={questionSchema}
      scrollToElement={scrollToElement}
      isPreview={survey.isPreview}
      isEdit={survey.isEdit}
      answered={answered}
      submitted={submitted}
      getQuestions={getQuestions}
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      onAddQuestionSubmit={onAddQuestionSubmit}
      error={error}
      editedQuestion={editedQuestion}
      setEditedQuestion={setEditedQuestion}
      loadingTypes={loadingTypes}
      imgData={imgData}
    />
  );
};

export default EditSurvey;
