import request from "../../utils/request";
import { getResData } from "../../utils/helpers";

export const fetchSurveys = async (data?: { length?: number; name?: string; start?: number }) => {
  try {
    const res = await request.post("/user/survey/list", data);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchSurveys error => ", error);
    console.log("Error Response fetchSurveys => ", error.response);
    return getResData(error.response, true);
  }
};

export const fetchRecentSurveys = async (data?: { length?: number; start?: number }) => {
  try {
    const res = await request.post("/user/survey/recent", data);
    return getResData(res.data);
  } catch (error) {
    console.error("fetchSurveys error => ", error);
    console.log("Error Response fetchSurveys => ", error.response);
    return getResData(error.response, true);
  }
};

export const deleteSurvey = async (data: { surveyId: number }) => {
  try {
    const res = await request.post("/user/survey/delete", data);
    return getResData(res.data);
  } catch (error) {
    console.error("deleteSurvey error => ", error);
    console.log("Error Response deleteSurvey => ", error.response);
    return getResData(error.response, true);
  }
};

export const disableSurvey = async (data: { surveyId: number }) => {
  try {
    const res = await request.post("/user/survey/disable", data);
    return getResData(res.data);
  } catch (error) {
    console.error("disableSurvey error => ", error);
    console.log("Error Response disableSurvey => ", error.response);
    return getResData(error.response, true);
  }
};

export const enableSurvey = async (data: { surveyId: number }) => {
  try {
    const res = await request.post("/user/survey/enable", data);
    return getResData(res.data);
  } catch (error) {
    console.error("enableSurvey error => ", error);
    console.log("Error Response enableSurvey => ", error.response);
    return getResData(error.response, true);
  }
};
