import React, { useState } from "react";
import { Field, FormikErrors, FormikTouched } from "formik";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputMask from "react-input-mask";
import "./input.scss";

type Props = {
    error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
    touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
    icon?: JSX.Element | JSX.Element[];
    name?: string;
    value?: string;
    placeholder?: string;
    mask?: string;
    handleChange?: any;
    handleBlur?: any;
    type: string;
    disabled?: boolean;
    required?: boolean;
};

const Input = (props: Props) => {
    const [isShown, setShow] = useState(false);
    const setPassword = (isShown: boolean) => (event: React.MouseEvent<HTMLElement>) => {
        setShow(isShown);
    };
    return (
        <div className={`${props.error /*&& props.touched*/ ? "input-block input-error" : "input-block"}`}>
            {props.type !== "hidden" && props.icon && <div className="input-icon">{props.icon}</div>}
            {props.type === "password" && (
                <div
                    className={`${isShown ? "input-password-icon shown" : "input-password-icon"}`}
                    onClick={setPassword(!isShown)}
                >
                    {isShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
            )}
            {
                props.type === "mask"
                    ?
                    <Field
                        name={props.name}
                        render={({ field }) => (
                            <InputMask
                                {...field}
                                mask={props.mask}
                                placeholder={props.placeholder}
                                type="text"
                                maskChar={null}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                className={
                                    props.error
                                        ? "text-input error"
                                        : "text-input"
                                }
                            />
                        )}
                    />
                    :
                    <Field
                        type={props.type === "password" && isShown ? "text" : props.type}
                        name={props.name}
                        value={props.type === "password" ? props.value.trim() : props.value}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        required={props.required}
                    />
            }
            {props.error && <p className={props.icon ? 'error-text color-red' : 'error-text-no-icon color-red' }>{props.error}</p>} {/* && props.touched*/}
        </div>
    );
};

export default Input;
