import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  CheckBoxOutlineBlank,
  RadioButtonUncheckedOutlined,
  RemoveCircleOutline as RemoveIcon,
  AddCircleOutline as AddIcon,
  Edit,
  Delete,
} from "@material-ui/icons";
import Input from "../../../../shared/components/Input/Input";
import { getQuestionTypesOptions } from "./services";
// CREATE SURVEY IMPORTS
// END

import { ANSWER } from "../../../../utils/validationParams";
import { SelectField } from "../../../../shared/components/Input/Select";
// selectors
// end

import "./surveyQuestion.scss";
import "./subQuestionsModal.scss";

import ModalComponent from "../../../../shared/components/Modal/Modal";

const subQuestionValidationSchema = Yup.object().shape({
  question: Yup.string()
    .min(1, "Question must be at least 1 character")
    .max(5000, "Question must be at most 5000 characters")
    .trim()
    .required("Question is required"),
  type: Yup.string().required("Question type required"),
  answers: Yup.array().when("type", (type) => {
    if (type === "TEXT") {
      return Yup.array().nullable();
    } else {
      return Yup.array().of(
        Yup.object({
          answer: Yup.string()
            .min(ANSWER.min.char, ANSWER.min.error)
            .max(ANSWER.max.char, ANSWER.max.error)
            .trim()
            .required("Answer is required"),
        })
      );
    }
  }),
});

const SubAnswer = ({
  values,
  errors,
  touched,
  arrayHelpers,
  type,
  addSubQuestions,
  removeQuestions,
  editQuestion,
  hideActionButtons,
}) => {
  let inputIcon = <div className="answer-input-icon" />;
  if (type === "CHECKBOX") {
    inputIcon = <CheckBoxOutlineBlank className="answer-input-icon material" />;
  } else if (type === "MULTIPLE_CHOICE") {
    inputIcon = <RadioButtonUncheckedOutlined className="answer-input-icon material" />;
  }
  return values.answers.map((answer, i) => {
    const name = `answers.${i}.answer`;
    return (
      <React.Fragment key={answer.id}>
        <div className="input-container" key={i}>
          {inputIcon}
          <Input
            name={name}
            value={values.answers[i].answer}
            error={
              touched?.answers &&
              touched?.answers[i] &&
              errors?.answers &&
              errors?.answers[i] &&
              errors?.answers[i]?.answer
            }
            touched={touched.answers && touched.answers[i]}
            placeholder="Answer"
            type="text"
            disabled={hideActionButtons}
          />
          {!hideActionButtons && (
            <div className="answers-btns">
              <AddIcon
                className="add-answer-icon"
                onClick={() => arrayHelpers.insert(i + 1, { answer: "", order: i + 1 })}
              />
              <RemoveIcon
                className="delete-answer-icon"
                onClick={() => {
                  if (values.answers.length === 1) return;
                  arrayHelpers.remove(i);
                }}
              />
            </div>
          )}

          <div className="sub-question-btn-wrapper">
            <button
              type="button"
              onClick={() => addSubQuestions(i)}
              className="primary-btn"
              disabled={hideActionButtons}
            >
              Add Sub Question
            </button>
          </div>
        </div>

        {answer?.followUpQuestions &&
          answer?.followUpQuestions?.length > 0 &&
          answer.followUpQuestions.map((item, index) => {
            return (
              <div className="sub-question-representative" key={item.id}>
                <div>{item.question}</div>
                <div className="sub-question-representative-btns">
                  <Edit
                    onClick={() => {
                      editQuestion(i, index);
                    }}
                  />
                  {!hideActionButtons && (
                    <Delete
                      onClick={() => {
                        removeQuestions(i, index);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </React.Fragment>
    );
  });
};

const SubQuestion = ({
  i,
  state,
  previousVal,
  setPreviousVal,
  isOpenModal,
  handleClose,
  hideActionButtons = false,
}) => {
  const typeOptions = getQuestionTypesOptions(state.types);

  return (
    <ModalComponent title="Add Sub Question" open={isOpenModal} handleClose={handleClose}>
      <Formik
        validateOnBlur={true}
        initialValues={{
          order: i,
          question: previousVal.question ? previousVal.question : "",
          type: previousVal.type || typeOptions[0].value,
          answers: previousVal.answers
            ? previousVal.answers
            : [
                { answer: "", order: 0 },
                { answer: "", order: 1 },
              ],
        }}
        validationSchema={subQuestionValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          values.answers = values.type === "TEXT" ? null : values.answers;
          setPreviousVal(values);
        }}
      >
        {({ values, setFieldValue, errors, touched, onSubmit }) => {
          const addSubQuestions = (addIndex) => {
            if (
              !values.answers[addIndex].hasOwnProperty("followUpQuestions") ||
              values.answers[addIndex].followUpQuestions.length === 0
            ) {
              setFieldValue(`answers[${addIndex}].followUpQuestions`, [{ open: true }]);
            } else {
              setFieldValue(`answers[${addIndex}].followUpQuestions`, [
                ...values.answers[addIndex].followUpQuestions,
                { open: true },
              ]);
            }
          };

          const editSubQuestion = (editIndex, followUpQuestionsIndex) => {
            const tempaArr = values.answers[editIndex].followUpQuestions.map((item, idx) => {
              if (idx === followUpQuestionsIndex) {
                item.open = true;
              }
              return item;
            });
            setFieldValue(`answers[${editIndex}].followUpQuestions`, tempaArr);
          };

          const removeQuestions = (removeIndex, followUpQuestionsIndex) => {
            setFieldValue(
              `answers[${removeIndex}].followUpQuestions`,
              values.answers[removeIndex].followUpQuestions.filter((item, index) => index !== followUpQuestionsIndex)
            );
          };

          return (
            <Form className="sub-question-form" handleSubmit={onSubmit}>
              <Input
                name={"question"}
                error={touched.question && errors.question}
                touched={touched.question}
                value={values.question}
                placeholder="Sub Question"
                type="text"
                disabled={hideActionButtons}
              />

              {hideActionButtons ? (
                <Input
                  error={touched.type && errors.type}
                  touched={touched.type}
                  name="type"
                  value={values.type}
                  placeholder="Question type"
                  type="text"
                  disabled={hideActionButtons}
                />
              ) : (
                <Field
                  component={SelectField}
                  options={typeOptions}
                  handleSelectChange={(value) => {
                    if (value !== "TEXT") {
                      values?.answers && values.answers.length > 0
                        ? setFieldValue("answers", values?.answers)
                        : setFieldValue("answers", [
                            { answer: "", order: 0 },
                            { answer: "", order: 1 },
                          ]);
                    }
                    setFieldValue("type", value);
                  }}
                  value={values.type}
                  name="type"
                  className="custom-select"
                />
              )}

              {values.type !== "TEXT" && (
                <FieldArray
                  name={"answers"}
                  render={(arrayHelpers) => (
                    <SubAnswer
                      values={values}
                      arrayHelpers={arrayHelpers}
                      type={values.type}
                      addSubQuestions={addSubQuestions}
                      removeQuestions={removeQuestions}
                      editQuestion={editSubQuestion}
                      errors={errors}
                      touched={touched}
                      hideActionButtons={hideActionButtons}
                    />
                  )}
                />
              )}
              {values.answers &&
                values.answers.length > 0 &&
                values.answers.map((answer, answerIdx) => {
                  return (
                    answer.followUpQuestions &&
                    answer.followUpQuestions.length > 0 &&
                    answer.followUpQuestions.map((followUpQuestion, index) => {
                      const tempItem = { ...followUpQuestion };
                      delete tempItem.open;
                      return (
                        <SubQuestion
                          key={followUpQuestion.id}
                          isOpenModal={followUpQuestion.open}
                          handleClose={() => {
                            setFieldValue(`answers[${answerIdx}].followUpQuestions[${index}]`, {tempItem});
                            if (!tempItem.question) {
                              setFieldValue(
                                `answers[${answerIdx}].followUpQuestions`,
                                values.answers[answerIdx].followUpQuestions.filter((item, idx) => idx !== index)
                              );
                            }
                          }}
                          i={index}
                          state={state}
                          previousVal={followUpQuestion}
                          setPreviousVal={(value) =>
                            setFieldValue(`answers[${answerIdx}].followUpQuestions[${index}]`, value)
                          }
                        />
                      );
                    })
                  );
                })}
              {!hideActionButtons && (
                <div className="questions-buttons-wrapper">
                  <button type="button" className="secondary-btn" onClick={handleClose}>
                    Cancel
                  </button>
                  <button type="submit" className="primary-btn save">
                    Save
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </ModalComponent>
  );
};

export default SubQuestion;
