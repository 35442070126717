import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "./Route";
import RouteSpinner from "../shared/components/Spinners/RouteSpinner";
import Layout from "../shared/components/Layout/Layout";

import { ROLES } from "../shared/constants";
import EditSurvey from "../app/Survey/containers/EditSurvey";

// Lazy Routes
const Home = lazy(() => import("../app/Home/containers/Home"));
const Dashboard = lazy(() => import("../app/Dashboard/containers/Dashboard"));
const Surveys = lazy(() => import("../app/Surveys/containers/Surveys"));
const Contacts = lazy(() => import("../app/Contacts/containers/Contacts"));
const CustomerProfile = lazy(() => import("../app/Customer/containers/Customer"));
const SingIn = lazy(() => import("../app/Auth/containers/SingIn/SingIn"));
const SignUp = lazy(() => import("../app/Auth/containers/SignUp/SignUp"));
const ForgotPassword = lazy(() => import("../app/Auth/containers/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("../app/Auth/containers/ResetPassword/ResetPassword"));
const Search = lazy(() => import("../app/Search/containers/Search"));
const Account = lazy(() => import("../app/Account/containers/Account"));
const Analyse = lazy(() => import("../app/Survey/containers/Analyse"));
const Survey = lazy(() => import("../app/Survey/containers/Survey"));
const Collectors = lazy(() => import("../app/Survey/containers/Collectors"));
const CopyPaste = lazy(() => import("../app/Survey/containers/CopyPaste"));
const Summary = lazy(() => import("../app/Survey/containers/Summary"));
const SurveysSummary = lazy(() => import("../app/Surveys/containers/SurveysSummary"));
// end

const defaultFallback = <RouteSpinner />;

const routes = [
  {
    path: `${process.env.REACT_APP_BASE_PATH}/`,
    exact: true,
    guard: PublicRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Home {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/sign-in`,
    exact: true,
    guard: PublicRoute,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <SingIn {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/sign-up`,
    exact: true,
    guard: PublicRoute,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <SignUp {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/forgot-password`,
    exact: true,
    guard: PublicRoute,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <ForgotPassword {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/reset-password`,
    exact: true,
    guard: PublicRoute,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <ResetPassword {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/dashboard`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Dashboard {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/search`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Search {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/copy-paste`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <CopyPaste {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/my-surveys`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Surveys {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/my-contacts`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Contacts {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/customer`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    role: ROLES.CUSTOMER,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <CustomerProfile {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/my-account`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Account {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/survey/analyse`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Analyse {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/survey`,
    exact: true,
    guard: PublicRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Survey {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/survey/preview`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Survey {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/survey/edit`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <EditSurvey {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/survey/collectors`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Collectors {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/survey/summary`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <Summary {...props} />
      </Suspense>
    ),
  },
  {
    path: `${process.env.REACT_APP_BASE_PATH}/surveys-summary`,
    exact: true,
    guard: PrivateRoute,
    layout: Layout,
    component: (props: any) => (
      <Suspense fallback={defaultFallback}>
        <SurveysSummary {...props} />
      </Suspense>
    ),
  },
  {
    path: "",
    guard: PublicRoute,
    component: () => <h1>Not Found</h1>,
  },
];

const RouterOutlet = () => (
  <Switch>
    {routes.map((route, i) => (
      <route.guard key={i} {...route} />
    ))}
  </Switch>
);

export default RouterOutlet;
