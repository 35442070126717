import { remove, cloneDeep } from "lodash";
import {
    SET_FORMAT_TYPES,
    SET_LOADING_TYPE,
    REMOVE_LOADING_TYPE,
    SET_SURVEY,
    LOADING_TYPES,
    SET_STEP,
    SET_ERROR,
    OPEN_CREATE_SURVEY_MODAL,
    CLOSE_CREATE_SURVEY_MODAL,
    PUSH_QUESTION,
    SET_QUESTION_STEP,
    PULL_QUESTION,
    SET_SURVEY_ID,
    SET_CATEGORY_TYPES,
    SET_SURVEY_COLLECTORS,
} from "./constants";
import {
    CreateSurveyState,
    CreateSurveyActionTypes,
    CreateSurveyModalState,
    CreateSurveyModalActionTypes,
} from "./types";

// FOR USE_REDUCER
export default function reducer(state: CreateSurveyState, action: CreateSurveyActionTypes): CreateSurveyState {
    switch (action.type) {
        case SET_LOADING_TYPE: {
            const newState = cloneDeep(state);
            newState.loadingTypes.push(action.payload);
            return newState;
        }
        case REMOVE_LOADING_TYPE: {
            const newState = cloneDeep(state);
            remove(newState.loadingTypes, (el) => el === action.payload);
            return newState;
        }
        case SET_FORMAT_TYPES: {
            const newState = { ...state };
            newState.formatTypes = action.payload;
            return newState;
        }
        case SET_CATEGORY_TYPES: {
            const newState = { ...state };
            newState.categoryTypes = action.payload;
            return newState;
        }
        case SET_SURVEY: {
            const newState = cloneDeep(state);
            newState.survey = action.payload;
            newState.loadingTypes = remove(newState.loadingTypes, LOADING_TYPES.CREATE);
            newState.error = "";
            return newState;
        }
        case SET_ERROR: {
            const newState = cloneDeep(state);
            newState.error = action.payload;
            return newState;
        }
        default:
            console.log(action);
            throw new Error();
    }
}

// GLOBAL REDUCER
const initialState: CreateSurveyModalState = {
    isOpen: false,
    isLoading: false,
    currentStep: 1,
    surveyId: null,
    collector: { type: null, id: null },
    questions: { step: 0, data: [] },
    collectors: [],
    location: "",
};

export const createSurveyModalReducer = (
    state: CreateSurveyModalState = initialState,
    action: CreateSurveyModalActionTypes
): CreateSurveyModalState => {
    switch (action.type) {
        case OPEN_CREATE_SURVEY_MODAL: {
            return { ...state, isOpen: true };
        }
        case CLOSE_CREATE_SURVEY_MODAL: {
            return {
                ...state,
                isOpen: false,
                isLoading: false,
                currentStep: 1,
                surveyId: null,
                collector: { type: null, id: null },
                questions: { step: 0, data: [] },
            };
        }
        case PUSH_QUESTION: {
            const newState = cloneDeep(state);
            if (typeof action.payload.insertToIndex === "number") {
                newState.questions.data[action.payload.insertToIndex] = action.payload.question;
            } else {
                newState.questions.data.push(action.payload.question);
            }
            return newState;
        }
        case PULL_QUESTION: {
            const newState = cloneDeep(state);
            newState.questions.data.splice(action.payload, 1);
            return newState;
        }
        case SET_QUESTION_STEP: {
            const newState = cloneDeep(state);
            newState.questions.step = action.payload;
            return newState;
        }
        case SET_STEP: {
            const newState = cloneDeep(state);
            newState.currentStep = action.payload;
            if (action.location) {
                newState.location = action.location;
            }
            return newState;
        }
        case SET_SURVEY_ID: {
            const newState = cloneDeep(state);
            newState.surveyId = action.payload;
            return newState;
        }
        case SET_SURVEY_COLLECTORS: {
            const newState = cloneDeep(state);
            newState.collectors = action.payload;
            return newState;
        }
        default:
            return state;
    }
};
