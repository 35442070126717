import React, { useState } from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const MultipleChoice = (props) => {
  const [checked, setChecked] = useState(null);
  const { field, error, form, name, options } = props;
  const fieldName = name || field.name;

  return (
    <React.Fragment>
      <RadioGroup {...field} {...props} name={fieldName}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            checked={checked === option.value}
            name={fieldName}
            value={option.value}
            control={<Radio />}
            label={option.label}
            onChange={() => {
              setChecked(option.value);
              form.setFieldValue(field.name, Number(option.value) ? Number(option.value) : option.value);
              if (props.scrollable) {
                props.scrollToElement(props.id);
              }
            }}
          />
        ))}
      </RadioGroup>

      {error && <p className="error-text">{form.errors[field.name]}</p>}
    </React.Fragment>
  );
};

export default MultipleChoice;
