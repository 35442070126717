import { combineReducers } from "redux";
import auth from "../app/Auth/reducer";
import { createSurveyModalReducer } from "../app/CreateSurvey/reducer";
import { setSurveysReducer } from "../app/Surveys/reducer";
import notificationReducer from "../shared/components/Notification/reducer";

const rootReducer = combineReducers({
    auth,
    createSurvey: createSurveyModalReducer,
    surveys: setSurveysReducer,
    notification: notificationReducer,
});
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
