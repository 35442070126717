import {
    SET_LOADING,
    SET_TYPE_OF_QUESTION,
    SET_QUESTION_TYPES,
    SET_ANSWER,
    DELETE_ANSWER,
    SET_STATE
} from "./constants";
import {
    LoadingAction,
    SetTypeOfQuestionAction,
    SetQuestionTypesAction,
    SetAnswerAction,
    DeleteAnswerAction,
    SetStateAction,
    AddQuestionState,
} from "./types";

// DELETE those setAnswer, deleteAnswer, clearTmpField

export const setLoading = (isLoading: boolean): LoadingAction => ({
    type: SET_LOADING,
    payload: isLoading,
});

export const setTypeOfQuestion = (type: string): SetTypeOfQuestionAction => ({
    type: SET_TYPE_OF_QUESTION,
    payload: type,
});

export const setQuestionTypes = (questionTypes: Array<string>): SetQuestionTypesAction => ({
    type: SET_QUESTION_TYPES,
    payload: questionTypes,
});

export const setAnswer = (answer: { name: string }): SetAnswerAction => ({
    type: SET_ANSWER,
    payload: answer,
});

export const deleteAnswer = (name: string): DeleteAnswerAction => ({
    type: DELETE_ANSWER,
    payload: name,
});

export const setState = (state: AddQuestionState): SetStateAction => ({
    type: SET_STATE,
    payload: state,
});

