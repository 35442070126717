import request from "../../utils/request";
import { getResData } from "../../utils/helpers";
import { ResData } from "../../shared/types";
import { FileType } from "./types";

export const fetchContacts = async (data: {
    email?: string;
    start: number;
    length: number;
    msisdn?: string;
    name?: string;
}) => {
    try {
        const res = await request.post("/user/contact/list", data);
        return getResData(res.data);
    } catch (error) {
        console.error("fetchContacts error => ", error);
        console.log("Error Response fetchContacts => ", error.response);
        return getResData(error.response, true);
    }
};

export const deleteContact = async (data: { id: number }) => {
    try {
        const res = await request.post("/user/contact/delete", data);
        return getResData(res.data);
    } catch (error) {
        console.error("deleteContact error => ", error);
        console.log("Error Response deleteContact => ", error.response);
        return getResData(error.response, true);
    }
};

export const addContact = async (data: { name: string; msisdn: string; email: string }) => {
    try {
        const res = await request.post("/user/contact/add", data);
        return getResData(res.data);
    } catch (error) {
        console.error("deleteContact error => ", error);
        console.log("Error Response deleteContact => ", error.response);
        return getResData(error.response, true);
    }
};

export const editContact = async (data: { id?: number; name: string; msisdn: string; email: string }) => {
    try {
        const res = await request.post("/user/contact/edit", data);
        return getResData(res.data);
    } catch (error) {
        console.error("deleteContact error => ", error);
        console.log("Error Response deleteContact => ", error.response);
        return getResData(error.response, true);
    }
};

export const uploadContactsFile = async (data: FileType): Promise<ResData> => {
    try {
        const res = await request.post("/user/contact/upload",  data);
        return getResData(res);
    } catch (error) {
        console.error("uploadContactsFile error => ", error);
        console.log("Error Response uploadContactsFile => ", error.response);
        return getResData(error.response, true);
    }
};

export const deleteContacts = async (deleteAll = false, data?: Array<number>): Promise<ResData> => {
    try {
        let res = null;
        if (deleteAll) {
            res = await request.post("/user/contact/delete/all");
        } else {
            res = await request.post("/user/contact/delete/bulk", { idList: data });
        }
        return getResData(res);
    } catch (error) {
        console.error("deleteContacts error => ", error);
        console.log("Error Response deleteContacts => ", error.response);
        return getResData(error.response, true);
    }
};

export const fetchContactFiles = async (data: { start: number; length: number; } = { start: 0, length: 10 }) => {
    try {
        const res = await request.post("/user/contact/file/list", data);
        return getResData(res.data);
    } catch (error) {
        console.error("fetchContacts error => ", error);
        console.log("Error Response fetchContactFiles => ", error.response);
        return getResData(error.response, true);
    }
};