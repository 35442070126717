import { Link as LinkIcon, Message as MessageIcon } from "@material-ui/icons";

export const COLLECTOR_TYPES = [
    {
        title: "SMS",
        id: "SMS",
        icon: MessageIcon,
        description: "Share your survey through sms.",
    },
    {
        title: "Web Link",
        id: "LINK",
        icon: LinkIcon,
        description: "This collector will generate URL that you can share with anyone or post on a website, email and more.",
    },
];

export const PHONE_REG = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
