import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { UserType } from "../../../../../app/Auth/types";
import { Formik, Form } from "formik";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import logo from "../../../../assets/golis-logo-white.png";
import logo2x from "../../../../assets/golis-logo-white@2x.png";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchInput from "../../../Input/SearchInput";

import "../styles/header.scss";

type HeaderProps = {
  onSubmit(data: { name: string }): void;
  isLoading?: boolean;
  logOut: () => void;
  auth: { isAuthenticated: boolean; isLoading: boolean | null; user: UserType };
  openMenu(event: React.MouseEvent<HTMLElement>): void;
  closeMenu(): void;
  openCreateModal: () => void;
  anchorEl: HTMLElement;
};

const HeaderComponent = (props: HeaderProps) => {
  const [isBurgerActive, setIsBurgerActive] = useState(false);

  const toggleBurger = () => {
    setIsBurgerActive(!isBurgerActive);
  };

  return (
    <header>
      <div className="logo">
        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
          <img src={logo} srcSet={`${logo2x} 2x`} alt="Golis Telecom" />
        </Link>
      </div>
      <div className={`links ${isBurgerActive ? "nav-active" : ""}`}>
        <NavLink to={`${process.env.PUBLIC_URL}/dashboard`} activeClassName="active">
          Dashboard
        </NavLink>
        <NavLink to={`${process.env.PUBLIC_URL}/my-surveys`}>My Surveys</NavLink>
        <NavLink to={`${process.env.PUBLIC_URL}/my-contacts`}>My Contacts</NavLink>
        <NavLink to={`${process.env.PUBLIC_URL}/surveys-summary`}>Surveys summary</NavLink>
      </div>
      <div className="search">
        <Formik
          initialValues={{
            name: "",
          }}
          onSubmit={props.onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <SearchInput
                name="name"
                placeholder="Search"
                submitFunc={() => {
                  props.onSubmit({ name: values?.name ? values?.name : "" });
                }}
                submitFuncClearIcon={() => {
                  setFieldValue("name", "");
                  props.onSubmit({ name: "" });
                }}
                searchIcon={<SearchIcon color="disabled" />}
                searchValue={values.name}
                isHeader
              />
            </Form>
          )}
        </Formik>
      </div>
      <div className="user">
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={props.openMenu}>
          <span className="user-name">{props.auth.user.fullName}</span>
          <div className="user-avatar">
            <AccountCircleIcon />
          </div>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={props.anchorEl}
          keepMounted
          open={Boolean(props.anchorEl)}
          onClose={props.closeMenu}
        >
          <MenuItem>
            <PersonIcon /> <Link to={`${process.env.PUBLIC_URL}/my-account`}>My Account</Link>
          </MenuItem>
          <MenuItem>
            <AssignmentIcon /> <span onClick={props.openCreateModal}>Create Survey</span>
          </MenuItem>
          <MenuItem onClick={props.logOut}>
            <ExitToAppIcon /> Log Out
          </MenuItem>
        </Menu>
      </div>
      <div className={`burger ${isBurgerActive ? "toggle" : ""}`} onClick={toggleBurger}>
        <div className={`${isBurgerActive ? "toggle" : ""} line1`}></div>
        <div className={`${isBurgerActive ? "toggle" : ""} line2`}></div>
        <div className={`${isBurgerActive ? "toggle" : ""} line3`}></div>
      </div>
    </header>
  );
};

export default HeaderComponent;
