import { CLEAR_SURVEYS, SET_SURVEYS } from "./constants";
import { Surveys, SetSurveysAction, ClearSurveysAction } from "./types";

export const setSurveys = (surveys: Surveys): SetSurveysAction => ({
    type: SET_SURVEYS,
    payload: surveys,
});

export const clearSurveys = (): ClearSurveysAction => ({
    type: CLEAR_SURVEYS,
});
