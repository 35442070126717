import React, { Fragment, useEffect, useState } from "react";
import { Element } from "react-scroll";
import { Form, Formik } from "formik";
import { AdditionalFields, QuestionType } from "../types";
import Question from "../containers/Question";
import * as Yup from "yup";
import { LOADING_TYPES, QUESTION_TYPE } from "../constants";
import Modal from "../../../shared/components/Modal/Modal";
import AddQuestionComponent from "../../CreateSurvey/containers/AddQuestion/AddQuestionComponent";
import Input from "../../../shared/components/Input/Input";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOn from "@material-ui/icons/LocationOn";
import EditSurveyInfo from "../../EditSurveyInfo/containers/EditSurveyInfo.jsx";
import { fetchQuestions, getSurvey } from "../services";

import "../styles/survey.scss";
import AddQuestionEditComponent from "../../CreateSurvey/containers/AddQuestion/AddQuestionEditComponent";

type Props = {
  questions: Array<QuestionType>;
  additionalFields: AdditionalFields;
  name: string;
  scrollable: boolean;
  onSubmit: (values: any) => void;
  backToSurveys: () => void;
  canSkipQuestions: boolean;
  questionSchema: any;
  initialValues: any;
  scrollToElement: (id: number) => void;
  isPreview?: boolean;
  isEdit?: boolean;
  answered?: boolean;
  submitted?: boolean;
  getQuestions: () => void;
  isModalOpen: boolean;
  toggleModal: () => void;
  onAddQuestionSubmit: (AddQuestionData) => void;
  error: string;
  editedQuestion: QuestionType;
  setEditedQuestion: (data: QuestionType) => void;
  loadingTypes: string[];
  imgData: string;
  id: number | undefined;
};

const SubQuestion = ({
  followUp,
  properties,
  questionsAnsweredIds,
  values,
  setFieldValue,
  errors,
  hideActionButtons = false,
}) => {
  useEffect(() => {
    if (followUp) {
      followUp.followUpQuestions.map((question) => {
        return setFieldValue("question-" + question.id, null);
      });
    }
  }, [followUp]);

  return followUp.followUpQuestions.map((question) => {
    const checkIsQuestionAnswered = questionsAnsweredIds.includes(question.id);

    const followUpQuestionsShown = checkIsQuestionAnswered
      ? question.answers.filter((item) => {
          return Array.isArray(values["question-" + question.id])
            ? values["question-" + question.id].filter((answer) => Number(answer) === Number(item.id))
            : Number(item.id) === Number(values["question-" + question.id]);
        })
      : [];

    return (
      <div style={{ marginLeft: "40px" }}>
        <Element name={"question-" + question.id} key={question.id}>
          <Question
            questions={properties.questions}
            question={question}
            error={errors["question-" + question.id] ? errors["question-" + question.id] : ""}
            value={
              question.answered
                ? question.answers.find((answer) => answer.answeredByUser).id
                : values["question-" + question.id]
                ? values["question-" + question.id]
                : question.type === QUESTION_TYPE.CHECKBOX
                ? []
                : ""
            }
            scrollToElement={properties.scrollToElement}
            scrollable={false}
            isEdit={false}
            getQuestions={properties.getQuestions}
            toggleModal={properties.toggleModal}
            setEditedQuestion={properties.setEditedQuestion}
            hideActionButtons={hideActionButtons}
          />
        </Element>
        {followUpQuestionsShown.map((followUpQuestionShown) => {
          const checkIsQuestionAnsweredF = Array.isArray(values["question-" + question.id])
            ? values["question-" + question.id]?.includes(followUpQuestionShown.id)
            : Number(values["question-" + question.id]) === Number(followUpQuestionShown.id);

          return (
            checkIsQuestionAnsweredF &&
            followUpQuestionShown && (
              <SubQuestion
                followUp={followUpQuestionShown}
                questionsAnsweredIds={questionsAnsweredIds}
                values={values}
                properties={properties}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            )
          );
        })}
      </div>
    );
  });
};

const EditSurveyComponent = (props: Props) => {
  const [localValues, setLocalValues] = useState(null);
  const [questionsAnswered, setQuestionsAnswered] = useState([]);
  const [questionsCounterAnswered, setQuestionsCounterAnswered] = useState([]);
  const [openEditSurveyModal, setOpenEditSurveyModal] = useState(false);
  const [questionSchema, setQuestionSchema] = useState({});

  const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false);
  const [questions, setQuestions] = useState(props.questions);
  const [updateQuestions, setUpdateQuestions] = useState(true);

  const [survey, setSurvey] = useState(null);
  const [updateSurveyData, setUpdateSurvayData] = useState(true);

  const fetchSurvey = async () => {
    try {
      const res = await getSurvey(props.id);
      setSurvey(res.data);
      setUpdateSurvayData(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.id) {
      if (updateSurveyData === true) {
        console.log("survey fetched");
        fetchSurvey();
      }
    }
  }, [props.id, updateSurveyData]);

  let rules = { ...questionSchema };

  let setFValue;

  useEffect(() => {
    props.questions.map((question) => {
      return setFValue("question-" + question.id, null);
    });
  }, [props.questions]);

  useEffect(() => {
    const tempArray = [];
    if (localValues) {
      for (const property in localValues) {
        if (localValues[property]) {
          if (Array.isArray(localValues[property]) && localValues[property].length > 0) {
            tempArray.push(property);
          } else if (!Array.isArray(localValues[property])) {
            tempArray.push(property);
          }
        }
      }
      setQuestionsAnswered(tempArray);
    } else {
      setQuestionsAnswered([]);
    }
  }, [localValues]);

  useEffect(() => {
    const tempArray = [];
    if (localValues) {
      for (const property in localValues) {
        if (property.includes("question-")) {
          if (
            props.questions.filter((item) => Number(item.id) === Number(property.replace("question-", ""))).length > 0
          ) {
            if (localValues[property]) {
              if (Array.isArray(localValues[property]) && localValues[property].length > 0) {
                tempArray.push(property);
              } else if (!Array.isArray(localValues[property])) {
                tempArray.push(property);
              }
            }
          }
        }
      }
      setQuestionsCounterAnswered(tempArray);
    } else {
      setQuestionsCounterAnswered([]);
    }
  }, [localValues]);

  useEffect(() => {
    if (localValues) {
      Object.keys(localValues).forEach((item) => {
        if (item.includes("question-")) {
          const id = item.replace("question-", "");
          const element = document.getElementById(id);
          if (!element) {
            setFValue(item, undefined);
          }
        }
      });
    }
  }, [localValues]);

  useEffect(() => {
    if (localValues) {
      const questionSchema = {};
      Object.keys(localValues).forEach((item) => {
        if (item.includes("question-")) {
          questionSchema[item] = props.canSkipQuestions
            ? Yup.string().nullable()
            : Yup.mixed().required("Please answer the question");
        }
      });
      setQuestionSchema(questionSchema);
    }
  }, [localValues]);

  useEffect(() => {
    if (survey?.id) {
      const getQuestions = async () => {
        const res = await fetchQuestions(survey?.id);
        setQuestions(res?.data?.questions || []);
        setUpdateQuestions(false);
      };
      if (updateQuestions) {
        getQuestions();
      }
    }
  }, [updateQuestions, survey?.id]);

  const questionsLeft = props?.questions?.length - questionsCounterAnswered.length;

  if (props.additionalFields.nameSupported) {
    rules = {
      ...rules,
      name: Yup.string().required("Name is required"),
    };
  }

  if (props.additionalFields.msisdnSupported) {
    rules = {
      ...rules,
      msisdn: Yup.string().required("Number is required"),
    };
  }

  if (props.additionalFields.locationSupported) {
    rules = {
      ...rules,
      location: Yup.string().required("Location is required"),
    };
  }

  const createQuestionsSchema = Yup.object().shape(rules);
  const questionsAnsweredIds = questionsAnswered.map((item) => Number(item.replace("question-", "")));

  return (
    <div className={`question-container container ${props.additionalFields.questionsCounter && "pb-70px"}`}>
      <div>
        {props.imgData && (
          <div className="imgLogo">
            <img src={`data:image/jpeg;base64,${props.imgData}`} alt="logo" />
          </div>
        )}
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ ...props.initialValues, name: "", location: "", msisdn: "" }}
          validationSchema={createQuestionsSchema}
          onSubmit={props.onSubmit}
        >
          {({ values, setFieldValue, errors, touched, handleChange, handleBlur }) => {
            setLocalValues(values);
            setFValue = setFieldValue;
            return (
              <Form>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <h2>{survey?.name && props.isEdit ? survey.name : props.name}</h2>
                  {props.isEdit && (
                    <button
                      type="button"
                      onClick={() => {
                        setOpenEditSurveyModal(true);
                      }}
                      className="primary-btn"
                    >
                      {survey?.status === "DRAFT" || survey?.status === "PENDING" ? "Edit Survey" : "View Survey"}
                    </button>
                  )}
                </div>
                {!props.isEdit && !props.isPreview && (
                  <>
                    {props.additionalFields.nameSupported && (
                      <Input
                        name="name"
                        placeholder="Name"
                        type="text"
                        error={errors.name}
                        touched={touched.name}
                        value={values.name}
                        icon={<PersonIcon />}
                      />
                    )}
                    {props.additionalFields.msisdnSupported && (
                      <Input
                        name="msisdn"
                        placeholder="+252"
                        type="mask"
                        mask="+252999999999"
                        error={errors.msisdn}
                        touched={touched.msisdn}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        icon={<PhoneIcon />}
                      />
                    )}
                    {props.additionalFields.locationSupported && (
                      <Input
                        name="location"
                        placeholder="Location"
                        type="text"
                        touched={touched.location}
                        error={errors.location}
                        value={values.location}
                        icon={<LocationOn />}
                      />
                    )}
                  </>
                )}

                {questions.map((question) => {
                  const checkIsQuestionAnswered = questionsAnsweredIds.includes(question.id);

                  const followUpQuestionsShown = checkIsQuestionAnswered
                    ? question.answers.filter((item) =>
                        Array.isArray(values["question-" + question.id])
                          ? values["question-" + question.id].filter((answer) => Number(answer) === Number(item.id))
                          : Number(item.id) === Number(values["question-" + question.id])
                      )
                    : [];

                  return (
                    <Fragment key={question.id}>
                      <Element name={"question-" + question.id} key={question.id}>
                        <Question
                          questions={questions}
                          question={question}
                          error={errors["question-" + question.id] ? errors["question-" + question.id] : ""}
                          value={
                            question.answered
                              ? question.answers.find((answer) => answer.answeredByUser).id
                              : values["question-" + question.id]
                              ? values["question-" + question.id]
                              : question.type === QUESTION_TYPE.CHECKBOX
                              ? []
                              : ""
                          }
                          scrollToElement={props.scrollToElement}
                          scrollable={props.scrollable}
                          isEdit={props.isEdit}
                          getQuestions={props.getQuestions}
                          toggleModal={props.toggleModal}
                          setEditedQuestion={props.setEditedQuestion}
                          setUpdateQuestions={setUpdateQuestions}
                          hideActionButtons={survey?.status === "DRAFT" || survey?.status === "PENDING" ? false : true}
                        />
                      </Element>
                      {followUpQuestionsShown.map((followUpQuestionShown) => {
                        const checkIsQuestionAnsweredF = Array.isArray(values["question-" + question.id])
                          ? values["question-" + question.id]?.includes(followUpQuestionShown.id)
                          : Number(values["question-" + question.id]) === Number(followUpQuestionShown.id);

                        return (
                          checkIsQuestionAnsweredF &&
                          followUpQuestionShown && (
                            <SubQuestion
                              followUp={followUpQuestionShown}
                              questionsAnsweredIds={questionsAnsweredIds}
                              values={values}
                              properties={props}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              hideActionButtons={
                                survey?.status === "DRAFT" || survey?.status === "PENDING" ? false : true
                              }
                            />
                          )
                        );
                      })}
                    </Fragment>
                  );
                })}

                {props.error && <p className="red">{props.error}</p>}

                {props.submitted ? (
                  props.answered ? (
                    <p className="green flex-column-center">Your answers was submitted!</p>
                  ) : (
                    <p className="error-text flex-column-center">Something went wrong. Try again.</p>
                  )
                ) : null}

                <div className="buttons-wrapper">
                  {props.isPreview || props.isEdit || props.answered ? null : (
                    <button type="submit" className="primary-btn">
                      Done
                    </button>
                  )}
                </div>
                {props.isEdit && (survey?.status === "DRAFT" || survey?.status === "PENDING") && (
                  <div className="buttons-wrapper">
                    <button type="button" onClick={() => setIsAddQuestionModalOpen(true)} className="primary-btn">
                      Add question
                    </button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
      {props.additionalFields.questionsCounter && (
        <div className="questions-counter-container">
          <div className="questions-counter">
            Questions left: <br /> <span>{questionsLeft}</span>
          </div>
        </div>
      )}
      <Modal
        open={props.isModalOpen}
        handleClose={() => {
          props.toggleModal();
          setUpdateQuestions(true);
        }}
        title={survey?.status === "DRAFT" || survey?.status === "PENDING" ? "Edit question" : "View Question"}
        modalClassName="create-survey-modal"
      >
        <AddQuestionComponent
          survey={survey}
          questions={props.questions}
          hideActionButtons={survey?.status === "DRAFT" || survey?.status === "PENDING" ? false : true}
          handleClose={props.toggleModal}
          onSubmit={props.onAddQuestionSubmit}
          editedQuestion={props.editedQuestion}
          edit={true}
          isLoading={props.loadingTypes.includes(LOADING_TYPES.QUESTION)}
          setUpdateQuestions={setUpdateQuestions}
        />
      </Modal>
      {props.isEdit && (
        <Modal
          open={isAddQuestionModalOpen}
          handleClose={() => {
            setIsAddQuestionModalOpen(false);
            setUpdateQuestions(true);
          }}
          title={"Add question"}
          modalClassName="create-survey-modal"
        >
          <AddQuestionEditComponent
            survey={survey}
            questions={props.questions}
            handleClose={() => {
              setIsAddQuestionModalOpen(false);
            }}
            onSubmit={() => {
              setUpdateQuestions(true);
            }}
            editedQuestion={props.editedQuestion}
            isLoading={props.loadingTypes.includes(LOADING_TYPES.QUESTION)}
            setUpdateQuestions={setUpdateQuestions}
          />
        </Modal>
      )}
      {props.isEdit && (
        <EditSurveyInfo
          isModalOpen={openEditSurveyModal}
          survey={survey}
          setLocalSurvey={setSurvey}
          setUpdateSurvayData={setUpdateSurvayData}
          title={survey?.status === "DRAFT" || survey?.status === "PENDING" ? "Edit Survey" : "View Survey"}
          hideActionButtons={survey?.status === "DRAFT" || survey?.status === "PENDING" ? false : true}
          handleClose={() => {
            setOpenEditSurveyModal(false);
          }}
          onSubmit={() => {
            setUpdateQuestions(true);
          }}
        />
      )}
    </div>
  );
};

export default EditSurveyComponent;
