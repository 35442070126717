import React, { useState } from "react";
import { QuestionType } from "../types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

type Props = {
  questions: Array<QuestionType>;
  question: QuestionType;
  deleteQuestion: (id: number) => void;
  moveQuestion: (movedQuestionId: number, position: string, questionId: number) => void;
  move: boolean;
  setMove: (move: boolean) => void;
  toggleModal: () => void;
  setEditedQuestion: (data: QuestionType) => void;
  hideActionButtons?: boolean;
};

const EditControls = (props: Props) => {
  const [position, setPosition] = useState("");
  const [questionId, setQuestionId] = useState(null);

  const setPositionHandler = (event: React.ChangeEvent<{ value: string }>) => {
    setPosition(event.target.value as string);
  };

  const setQuestionHandler = (event: React.ChangeEvent<{ value: string }>) => {
    setQuestionId(event.target.value as string);
  };

  return (
    <>
      <div className="buttons-wrapper">
        <button
          type="button"
          className="secondary-btn"
          onClick={() => {
            props.setEditedQuestion(props.question);
            props.toggleModal();
          }}
        >
          {props?.hideActionButtons ? "View" : "Edit"}
        </button>
        {!props?.hideActionButtons && (
          <>
            <button
              type="button"
              className={props.move ? `secondary-btn active` : `secondary-btn`}
              onClick={() => props.setMove(!props.move)}
            >
              Move
            </button>
            <button
              type="button"
              className="secondary-btn"
              onClick={() => {
                props.deleteQuestion(props.question.id);
              }}
            >
              Delete
            </button>
          </>
        )}

        {props.move && (
          <div className="move-controls">
            <FormControl>
              <InputLabel id="position-label">Position</InputLabel>
              <Select
                labelId="position-label"
                id="position"
                value={position}
                onChange={setPositionHandler}
                autoWidth={true}
              >
                <MenuItem value={"Before"}>Before</MenuItem>
                <MenuItem value={"After"}>After</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="question-label">Question</InputLabel>
              <Select
                labelId="question-label"
                id="question"
                value={questionId}
                onChange={setQuestionHandler}
                autoWidth={true}
              >
                {props.questions
                  .filter((question) => question.id !== props.question.id)
                  .map((question) => {
                    return <MenuItem value={question.id}>{question.question}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <button
              type="button"
              className="primary-btn"
              onClick={() => props.moveQuestion(props.question.id, position, questionId)}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditControls;
