export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_USER = "SET_USER";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_AUTH_STATE = "SET_AUTH_STATE";

export type SingUpSubmitData = {
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
    msisdn: string;
    terms: boolean;
};


export type SignInSubmitData = {
    password: string;
    msisdn: string;
}

export type ForgotPasswordSubmitData = {
    msisdn: string;
}

export type ResetPasswordSubmitData = {
    password: string;
    confirmPassword: string;
}