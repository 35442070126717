import React from "react";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./modal.scss";
import CancelIcon from "@material-ui/icons/Cancel";

type Props = {
    withAnimationFinish?: boolean;
    children: any;
    modalClassName?: string;
    open: boolean;
    handleClose: () => void;
    title: string;
    error?: string | null;
    withError?: boolean;
};

export default function ModalComponent(props: Props) {
    return (
        <Modal
            className={`modal-container ${props.modalClassName}`}
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onBackdropClick={props.handleClose}
            style={{ outline: "none" }}
        >
            <Fade in={props.open}>
                <div className="modal-component">
                    <div className="modal-header">
                        <h2>{props.title}</h2>
                        <div className="close-icon" onClick={props.handleClose}>
                            <CancelIcon />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="children-content">{props.children}</div>
                        {props.withError && props.error && <p className="error-text">{props.error}</p>}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
