import React, { useState } from "react";
import { Survey } from "../types";
import ImageUploader from "react-images-upload";
import { isEmpty } from "../../../utils/helpers";
import defaultLogo from "../assets/default-image.svg";

type Props = {
    handleClose: () => void;
    onSubmit: (surveyId: number, imageString: string) => void;
    survey: Survey;
    error: string;
    isLoading: boolean;
    onLogoSkip: () => void;
};

const AddSurveyLogo = (props: Props) => {
    const [imageData, setImage] = useState("");
    const [error, setError] = useState("");

    const onDrop = (pictureFiles, pictureDataURLs) => {
        if (!isEmpty(pictureDataURLs)) {
            const baseIdx = pictureDataURLs[0].indexOf("base64");
            const base64 = pictureDataURLs[0].substring(baseIdx + 7);
            setImage(base64);
        }
    };

    const onSubmit = () => {
        if (isEmpty(imageData)) {
            return setError("Please add logo!");
        }
        props.onSubmit(props.survey.id, imageData);
    };

    return (
        <div className="add-survey-logo-container">
            <div className="content">
                <h3>Add Your Logo</h3>
                {!imageData && (
                    <div className="default-image">
                        <img src={defaultLogo} alt="Logo" />
                    </div>
                )}
                <ImageUploader
                    withIcon={false}
                    withLabel={false}
                    buttonClassName="primary-btn"
                    buttonText="Upload Logo"
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png", ".jpeg", ".bmp"]}
                    maxFileSize={5242880}
                    withPreview={true}
                    singleImage={true}
                />
            </div>
            <div className="buttons-wrapper">
                <button onClick={props.onLogoSkip} className="secondary-btn" disabled={props.isLoading}>
                    Skip
                </button>
                <button onClick={onSubmit} className="primary-btn" disabled={props.isLoading}>
                    Next
                </button>
            </div>

            <p className="error-text">{error}</p>
        </div>
    );
};

export default AddSurveyLogo;
