import React, { useReducer, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { cloneDeep, isEqual } from "lodash";
import {
  CheckBoxOutlineBlank,
  RadioButtonUncheckedOutlined,
  RemoveCircleOutline as RemoveIcon,
  AddCircleOutline as AddIcon,
  ArrowBack,
  ArrowForward,
  Edit,
  Delete,
  TextFields,
} from "@material-ui/icons";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Input from "../../../../shared/components/Input/Input";
import reducer from "./reducer";
import { setLoading, setQuestionTypes, setTypeOfQuestion, setState } from "./actions";
import { getQuestionTypesOptions } from "./services";
import { AddQuestionState } from "./types";
// CREATE SURVEY IMPORTS
import { pushQuestion, setQuestionStep, pullQuestion } from "../../actions";
import { fetchQuestionTypes } from "../../services";
import { Survey } from "../../types";
import { QuestionType } from "../../../Survey/types";
// END

import { isEmpty } from "../../../../utils/helpers";
import { ANSWER } from "../../../../utils/validationParams";
import { SelectField } from "../../../../shared/components/Input/Select";
// selectors
import { surveyModalQuestions } from "../../../../redux/selectors";
// end

import "./surveyQuestion.scss";

import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import ListIcon from "@material-ui/icons/List";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import SubQuestion from "./SubQuestionsModal.jsx";
import { editQuestion } from "../../../Survey/services";

// TYPES
type Answer = {
  answer: string;
  order: number;
};
type FormikValues = {
  question_type: string;
  question: string;
  answers: any;
  order?: number;
};
export type AddQuestionData = {
  question: string;
  type: string;
  surveyId: number;
  answers: Array<Answer>;
  order: number;
};

type Props = {
  handleClose: () => void;
  onSubmit: (data: AddQuestionData[]) => void;
  survey?: Survey;
  editedQuestion?: QuestionType;
  error?: string;
  isLoading: boolean;
  setUpdateQuestions?: any;
  hideActionButtons?: boolean;
  questions?: any;
  edit?: boolean;
};
// END

const initialState: AddQuestionState = {
  questionType: "",
  isLoading: false,
  types: [],
};
export const QUESTION_ICON = {
  DROPDOWN: <UnfoldMoreIcon />,
  MULTIPLE_CHOICE: <ListIcon />,
  CHECKBOX: <CheckBoxIcon />,
  TEXT: <TextFields />,
};

const AddQuestionComponent = (props: Props) => {
  const [state, dispatchAction] = useReducer(reducer, initialState);

  // redux
  const dispatch = useDispatch();
  const questionsStore = useSelector(surveyModalQuestions);
  // end

  useEffect(() => {
    getTypesOfQuestion();
  }, []);

  const getTypesOfQuestion = async () => {
    dispatchAction(setLoading(true));
    const resData = await fetchQuestionTypes();
    dispatchAction(setLoading(false));
    if (!isEmpty(resData) && !isEmpty(resData.data)) {
      return dispatchAction(setQuestionTypes(resData.data));
    }
    console.log("questions were not found", resData);
  };

  const onQuestionTypeChange = (value: string) => {
    dispatchAction(setTypeOfQuestion(value));
  };

  const getInitialFormValues = () => {
    let result = {
      question: props.editedQuestion ? props.editedQuestion.question : "",
      question_type: props.editedQuestion ? props.editedQuestion.type : typeOptions[0].value,
      order: props?.editedQuestion?.order ? props?.editedQuestion?.order : undefined,
      answers: props.editedQuestion
        ? props.editedQuestion.answers
        : [
            {
              answer: "",
              order: 0,
            },
            {
              answer: "",
              order: 1,
            },
            {
              answer: "",
              order: 2,
            },
          ],
    };

    if (questionsStore.data[questionsStore.step]) {
      result = questionsStore.data[questionsStore.step];
      if (questionsStore.data[questionsStore.step].question_type !== state.questionType) {
        onQuestionTypeChange(questionsStore.data[questionsStore.step].question_type);
      }
    }
    return result;
  };

  const getValidationSchema = () => {
    const result = {
      question: Yup.string()
        .min(1, "Question must be at least 1 character")
        .max(5000, "Question must be at most 5000 characters")
        .trim()
        .required("Question is required"),
      question_type: Yup.string().required("Question type required"),
      answers:
        state.questionType === "TEXT"
          ? Yup.array().nullable()
          : Yup.array().of(
              Yup.object({
                answer: Yup.string()
                  .min(ANSWER.min.char, ANSWER.min.error)
                  .max(ANSWER.max.char, ANSWER.max.error)
                  .trim()
                  .required("Answer is required"),
              })
            ),
    };
    return result;
  };

  const addQuestionSchema = Yup.object().shape(getValidationSchema());

  const renderAnswer = (values, errors, touched, arrayHelpers, setFieldValue) => {
    let inputIcon = <div className="answer-input-icon" />;
    if (state.questionType === "CHECKBOX") {
      inputIcon = <CheckBoxOutlineBlank className="answer-input-icon material" />;
    } else if (state.questionType === "MULTIPLE_CHOICE") {
      inputIcon = <RadioButtonUncheckedOutlined className="answer-input-icon material" />;
    }

    const addSubQuestions = (addIndex) => {
      if (!values.answers[addIndex].hasOwnProperty("followUpQuestions")) {
        setFieldValue(`answers[${addIndex}].followUpQuestions`, [{ open: true }]);
      } else {
        setFieldValue(`answers[${addIndex}].followUpQuestions`, [
          ...values.answers[addIndex].followUpQuestions,
          { open: true },
        ]);
      }
    };

    const editSubQuestion = (editIndex, followUpQuestionsIndex) => {
      const tempaArr = values.answers[editIndex].followUpQuestions.map((item, idx) => {
        if (idx === followUpQuestionsIndex) {
          item.open = true;
        }
        return item;
      });
      setFieldValue(`answers[${editIndex}].followUpQuestions`, tempaArr);
    };

    const removeQuestions = (removeIndex, followUpQuestionsIndex) => {
      setFieldValue(
        `answers[${removeIndex}].followUpQuestions`,
        values.answers[removeIndex].followUpQuestions.filter((item, index) => index !== followUpQuestionsIndex)
      );
    };

    return values.answers.map((answer, i) => {
      const name = `answers.${i}.answer`;
      return (
        <React.Fragment key={i}>
          <div className="input-container" key={i}>
            {inputIcon}
            <Input
              error={
                touched?.answers &&
                touched?.answers[i] &&
                errors?.answers &&
                errors?.answers[i] &&
                errors?.answers[i]?.answer
              }
              touched={touched.answers && touched.answers[i]}
              name={name}
              value={values.answers[i].answer}
              placeholder="Enter an answer"
              type="text"
              disabled={props?.hideActionButtons}
            />
            {!props?.hideActionButtons && (
              <div className="answers-btns">
                <AddIcon className="add-answer-icon" onClick={() => arrayHelpers.insert(i + 1, "")} />
                <RemoveIcon
                  className="delete-answer-icon"
                  onClick={() => {
                    if (values.answers.length === 1) return;
                    arrayHelpers.remove(i);
                  }}
                />
              </div>
            )}
            <div className="sub-question-btn-wrapper">
              <button
                type="button"
                onClick={() => addSubQuestions(i)}
                className="primary-btn"
                disabled={props?.hideActionButtons}
              >
                Add Sub Question
              </button>
            </div>
          </div>
          {answer?.followUpQuestions &&
            answer?.followUpQuestions?.length > 0 &&
            answer.followUpQuestions.map((item, index) => {
              return (
                <div className="sub-question-representative" key={item.id}>
                  <div>{item.question}</div>
                  <div className="sub-question-representative-btns">
                    <Edit onClick={() => editSubQuestion(i, index)} />
                    {!props?.hideActionButtons && (
                      <Delete
                        onClick={() => {
                          removeQuestions(i, index);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          {values.answers[i] &&
            values.answers[i].followUpQuestions &&
            values.answers[i].followUpQuestions.map((item, index) => {
              const tempItem = { ...item };
              delete tempItem.open;

              return (
                <SubQuestion
                  key={index}
                  i={index}
                  state={state}
                  previousVal={item}
                  setPreviousVal={(value) => setFieldValue(`answers[${i}].followUpQuestions[${index}]`, value)}
                  isOpenModal={item.open}
                  hideActionButtons={props?.hideActionButtons}
                  handleClose={() => {
                    setFieldValue(`answers[${i}].followUpQuestions[${index}]`, tempItem);
                    if (!tempItem.question) {
                      setFieldValue(
                        `answers[${i}].followUpQuestions`,
                        values.answers[i].followUpQuestions.filter((item, idx) => idx !== index)
                      );
                    }
                  }}
                />
              );
            })}
        </React.Fragment>
      );
    });
  };

  const onAddQuestionClick = async (values: FormikValues, submitForm: () => Promise<any>) => {
    const isValid = await addQuestionSchema.isValid(values);
    if (!isValid) return await submitForm();
    let nextStepIdx = questionsStore.step + 1;
    // add new question to the end
    if (questionsStore.step === questionsStore.data.length) {
      dispatch(pushQuestion(values));
      dispatch(setQuestionStep(nextStepIdx));
    } else if (questionsStore.step !== questionsStore.data.length) {
      dispatch(pushQuestion(values, questionsStore.step));
      nextStepIdx = questionsStore.data.length;
      dispatch(setQuestionStep(nextStepIdx));
    }
    const newState = cloneDeep(initialState);
    newState.types = state.types;
    dispatchAction(setState(newState));
  };

  const isInitialValues = (values: FormikValues) => {
    if (values.question === "" && values.answers.every((a) => a === "")) {
      return true;
    }
    return false;
  };

  const onPrevQuestionClick = async (values: FormikValues, submitForm: () => Promise<any>) => {
    if (!isEqual(values, questionsStore.data[questionsStore.step]) && !isInitialValues(values)) {
      const isValid = await addQuestionSchema.isValid(values);
      if (!isValid) return await submitForm();
      dispatch(pushQuestion(values, questionsStore.step));
    }
    dispatch(setQuestionStep(questionsStore.step - 1));
  };
  const onNextQuestionClick = async (values: FormikValues, submitForm: () => Promise<any>) => {
    if (!isEqual(values, questionsStore.data[questionsStore.step])) {
      const isValid = await addQuestionSchema.isValid(values);
      if (!isValid) return await submitForm();
      dispatch(pushQuestion(values, questionsStore.step));
    }
    dispatch(setQuestionStep(questionsStore.step + 1));
  };
  const onRemoveQuestionClick = () => {
    dispatch(pullQuestion(questionsStore.step));
    let nextStepIdx = questionsStore.step !== 0 ? questionsStore.step - 1 : 0;
    dispatch(setQuestionStep(nextStepIdx));
  };

  const getFormattedQuestion = (data: FormikValues, index?: number) => {
    const result = {
      type: data.question_type,
      question: data.question,
      answers: [],
      order: typeof data?.order === "number" ? data?.order : index,
    };
    result.answers =
      data.question_type === "TEXT"
        ? null
        : data.answers.map((value, i) => {
            value["order"] = typeof value?.order === "number" ? value?.order : i;
            return value;
          });

    return result;
  };

  function removeEmptyFollowUpQuestions(questions) {
    return questions.map((question) => {
      const { followUpQuestions, ...rest } = question;

      if (followUpQuestions && followUpQuestions.length === 0) {
        return rest;
      } else if (followUpQuestions) {
        rest.followUpQuestions = removeEmptyFollowUpQuestions(followUpQuestions);
      }

      if (rest.answers) {
        rest.answers = removeEmptyFollowUpQuestions(rest.answers);
      }

      return rest;
    });
  }

  function setAnswersToNull(questions) {
    return questions.map((question) => {
      if (question.type === "TEXT") {
        return { ...question, answers: null };
      }

      if (question.followUpQuestions) {
        question.followUpQuestions = setAnswersToNull(question.followUpQuestions);
      }

      if (question.answers) {
        question.answers = setAnswersToNull(question.answers);
      }

      return question;
    });
  }

  const onSubmit = async (values: FormikValues) => {
    const result = [];
    if (props.edit) {
      result.push(getFormattedQuestion(values, props.editedQuestion.order));

      const newResult = setAnswersToNull(removeEmptyFollowUpQuestions(result));
      const questions = {
        ...newResult[0],
        questionId: props.editedQuestion.id,
      };

      await editQuestion(questions);
      props.handleClose();
    } else {
      if (!isEmpty(questionsStore.data)) {
        questionsStore.data.forEach((question, index) => {
          result.push(getFormattedQuestion(question, index));
        });
      }

      if (questionsStore.step === questionsStore.data.length) {
        result.push(getFormattedQuestion(values, questionsStore.step));
      } else if (!isEmpty(questionsStore.data)) {
        result[questionsStore.step] = getFormattedQuestion(values, questionsStore.step);
      }

      const newResult = setAnswersToNull(removeEmptyFollowUpQuestions(result));
      const questions = {
        questions: newResult,
        surveyId: props.survey ? props.survey.id : null,
      };

      await props.onSubmit(questions as any);
    }

    props.setUpdateQuestions && props.setUpdateQuestions(true);
  };

  const typeOptions = getQuestionTypesOptions(state.types);

  return (
    <div className="add-survey-question-container">
      <div className="content">
        {!isEmpty(state.types) && (
          <Formik
            validateOnBlur={true}
            initialValues={getInitialFormValues()}
            validationSchema={addQuestionSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, touched, submitForm }) => {
              return (
                <>
                  {!props.editedQuestion && (
                    <div className="question-nav">
                      {questionsStore.step !== 0 ? (
                        <ArrowBack onClick={() => onPrevQuestionClick(values, submitForm)} />
                      ) : (
                        <span className="empty-icon" />
                      )}
                      <div className="question-count-container">
                        {questionsStore.data.length > 0 &&
                          (questionsStore.step !== 0 || questionsStore.data.length > 1) && (
                            <Delete onClick={onRemoveQuestionClick} />
                          )}
                        <p className="question-count">{`Question ${questionsStore.step + 1}`}</p>
                      </div>
                      {questionsStore.step !== questionsStore.data.length &&
                      questionsStore.step + 1 !== questionsStore.data.length ? (
                        <ArrowForward onClick={() => onNextQuestionClick(values, submitForm)} />
                      ) : (
                        <span className="empty-icon" />
                      )}
                    </div>
                  )}
                  <Form>
                    <Input
                      error={touched.question && errors.question}
                      touched={touched.question}
                      name="question"
                      value={values.question}
                      placeholder="Question"
                      type="text"
                      disabled={props?.hideActionButtons}
                    />
                    {props?.hideActionButtons ? (
                      <Input
                        error={touched.question_type && errors.question_type}
                        touched={touched.question_type}
                        name="question_type"
                        value={values.question_type}
                        placeholder="Question type"
                        type="text"
                        disabled={props?.hideActionButtons}
                      />
                    ) : (
                      <Field
                        component={SelectField}
                        options={typeOptions}
                        handleSelectChange={(value) => {
                          if (value !== "TEXT") {
                            values?.answers && values.answers.length > 0
                              ? setFieldValue("answers", values?.answers)
                              : setFieldValue("answers", [
                                  { answer: "", order: 0 },
                                  { answer: "", order: 1 },
                                ]);
                          }
                          onQuestionTypeChange(value);
                        }}
                        defaultValue={typeOptions[0]}
                        name="question_type"
                        className="custom-select"
                      />
                    )}

                    {values.question_type !== "TEXT" && (
                      <FieldArray
                        name={"answers"}
                        render={(arrayHelpers) => {
                          return renderAnswer(values, errors, touched, arrayHelpers, setFieldValue);
                        }}
                      />
                    )}
                    {!props?.hideActionButtons && (
                      <div className="questions-buttons-wrapper">
                        <button type="button" className="secondary-btn" onClick={props.handleClose}>
                          Cancel
                        </button>
                        <button type="submit" className="primary-btn save">
                          Save
                        </button>

                        {!props.editedQuestion && (
                          <button
                            type="button"
                            className="primary-btn flex-row-center"
                            onClick={() => onAddQuestionClick(values, submitForm)}
                          >
                            Next Question <NavigateNextIcon />
                          </button>
                        )}
                      </div>
                    )}
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AddQuestionComponent;
