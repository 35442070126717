export const QUESTION_TYPE = {
    DROPDOWN: "DROPDOWN",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
    CHECKBOX: "CHECKBOX",
    TEXT: "TEXT",
};

export const CHART_COLORS = {
    backgroundColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
    ]
};

export const LOADING_TYPES = {
    QUESTION: "question"
}

export const CHART_OPTIONS = {
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 100,
                    callback: function (value) {
                        return value + "%";
                    },
                },
            },
        ],
        yAxes: [
            {
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                },
            },
        ],
    },
};
