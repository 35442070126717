import React, { useReducer, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import reducer from "../reducer";
import { CreateSurveyState } from "../types";
import {
    setFormatTypes,
    setLoadingType,
    removeLoadingType,
    setSurvey,
    setError,
    setStep,
    closeCreateSurveyModal,
    setCategoryTypes,
} from "../actions";
import { useHistory, useLocation } from "react-router-dom";
import CreateSurveyComponent, { CreateSurveyData } from "../components/CreateSurvey";
import Modal from "../../../shared/components/Modal/Modal";
import {
    fetchFormatTypes,
    createSurvey,
    uploadSurveyLogo,
    addSurveyQuestions,
    fetchCategoriesTypes,
} from "../services";
import { LOADING_TYPES } from "../constants";
import { isEmpty } from "../../../utils/helpers";
import AddSurveyLogo from "../components/AddSurveyLogo";
import AddQuestionComponent, { AddQuestionData } from "./AddQuestion/AddQuestionComponent";
import {
    createSurveyModalOpen,
    surveyModalStep,
    surveyModalId,
    selectExecFromLocation,
} from "../../../redux/selectors";
import { fetchSurveys } from "../../Surveys/services";
import { setSurveys } from "../../Surveys/action";
import AddCollectorComponent from "./AddCollector/AddCollector";
import { PAGINATION_LIMIT } from "../../../shared/constants";
import "../styles/createSurvey.scss";

type Props = {
    // isOpen: boolean;
    // handleClose: () => void;
};

const initialState: CreateSurveyState = {
    formatTypes: [],
    loadingTypes: [],
    categoryTypes: [],
    survey: null,
    error: "",
};

const CreateSurvey = (props: Props) => {
    const [state, dispatchAction] = useReducer(reducer, initialState);

    const isModalOpen = useSelector(createSurveyModalOpen);
    const currentStep = useSelector(surveyModalStep);
    const executedFrom = useSelector(selectExecFromLocation);
    const surveyId = useSelector(surveyModalId);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        getFormatTypes();
        getCategoriesTypes();
    }, []);

    useEffect(() => {
        dispatchAction(setSurvey({ id: surveyId }));
    }, [surveyId]);

    const handleClose = () => {
        dispatch(closeCreateSurveyModal());
    };

    const getFormatTypes = async () => {
        dispatchAction(setLoadingType(LOADING_TYPES.FORMAT_TYPES));
        const resData = await fetchFormatTypes();
        dispatchAction(removeLoadingType(LOADING_TYPES.FORMAT_TYPES));
        if (!isEmpty(resData) && !isEmpty(resData.data)) {
            dispatchAction(setFormatTypes(resData.data));
        }
    };
    const getCategoriesTypes = async () => {
        const resData = await fetchCategoriesTypes();
        if (!isEmpty(resData) && !isEmpty(resData.data)) {
            const categories = resData.data.map((category) => {
                return { value: category.toLowerCase(), label: category };
            });
            dispatchAction(setCategoryTypes(categories));
        }
    };

    const getSurveys = async (data?: { length?: number; name?: string; start?: number }) => {
        const resData = await fetchSurveys(data);
        dispatchAction(removeLoadingType(LOADING_TYPES.CREATE));
        if (resData && resData.result) {
            return dispatch(setSurveys(resData.data));
        }
    };

    const onCreateSurveySubmit = async (values: CreateSurveyData) => {
        if (state.error) dispatchAction(setError(""));
        dispatchAction(setLoadingType(LOADING_TYPES.CREATE));
        const resData = await createSurvey(values);
        if (!isEmpty(resData) && resData.success) {
            await getSurveys({ start: 0, length: PAGINATION_LIMIT });
            dispatch(setStep(2));
            return dispatchAction(setSurvey({ ...values, id: resData.data }));
        }
        if (!isEmpty(resData) && resData.error) {
            dispatchAction(setError(resData.error));
        }
        dispatchAction(removeLoadingType(LOADING_TYPES.CREATE));
    };

    const onAddLogoSubmit = async (surveyId: number, imageData: string) => {
        if (state.error) dispatchAction(setError(""));
        dispatchAction(setLoadingType(LOADING_TYPES.LOGO));
        const resData = await uploadSurveyLogo(surveyId, imageData);
        dispatchAction(removeLoadingType(LOADING_TYPES.LOGO));
        if (!isEmpty(resData) && resData.success) {
            history.push(`${location.pathname}?step=3&question=0`);
            return dispatch(setStep(3));
        }
        if (!isEmpty(resData) && resData.error) {
            dispatchAction(setError(resData.error));
        }
    };

    const onLogoSkip = () => {
        history.push(`${location.pathname}?step=3&question=0`);
        return dispatch(setStep(3));
    };

    const onAddQuestionSubmit = async (data: AddQuestionData[]) => {
        if (state.error) dispatchAction(setError(""));
        dispatchAction(setLoadingType(LOADING_TYPES.ADD_QUESTION));
        const resData = await addSurveyQuestions(data);
        dispatchAction(removeLoadingType(LOADING_TYPES.ADD_QUESTION));
        if (!isEmpty(resData) && resData.success) {
            return dispatch(setStep(4));
        }
        if (!isEmpty(resData) && resData.error) {
            dispatchAction(setError(resData.error));
        }
    };

    const renderStepComponent = () => {
        if (currentStep === 1) {
            return (
                <CreateSurveyComponent
                    handleClose={handleClose}
                    onSubmit={onCreateSurveySubmit}
                    error={state.error}
                    isLoading={state.loadingTypes.includes(LOADING_TYPES.CREATE)}
                    categories={state.categoryTypes}
                />
            );
        } else if (currentStep === 2) {
            return (
                <AddSurveyLogo
                    handleClose={handleClose}
                    onSubmit={onAddLogoSubmit}
                    survey={state.survey}
                    error={state.error}
                    isLoading={state.loadingTypes.includes(LOADING_TYPES.LOGO)}
                    onLogoSkip={onLogoSkip}
                />
            );
        } else if (currentStep === 3) {
            return (
                <AddQuestionComponent
                    handleClose={handleClose}
                    onSubmit={onAddQuestionSubmit}
                    survey={state.survey}
                    error={state.error}
                    isLoading={state.loadingTypes.includes(LOADING_TYPES.ADD_QUESTION)}
                />
            );
        } else if (currentStep === 4) {
            return (
                <AddCollectorComponent
                    handleClose={handleClose}
                    survey={state.survey}
                    error={state.error}
                    executedFrom={executedFrom}
                    getSurveys={getSurveys}
                />
            );
        }
    };

    return (
        <Modal
            open={isModalOpen}
            handleClose={handleClose}
            title={state.survey && state.survey.name ? state.survey.name : "Create Surveys"}
            modalClassName="create-survey-modal"
            error={state.error}
            withError={true}
        >
            {renderStepComponent()}
        </Modal>
    );
};
const propsAreEqual = (prevProps: Props, nextProps: Props) => {
    return false;
};

export default memo(CreateSurvey, propsAreEqual);
