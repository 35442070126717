export const getToken = () => {
    const token = localStorage.getItem("jwt_token");
    if (!token) return null;
    return token;
};

export const getRefreshToken = () => {
    const refreshData = localStorage.getItem("refreshData");
    if (!refreshData) return null;
    return JSON.parse(refreshData);
};

type RefreshData = {
    expired?: Date;
    refreshToken: string;
};
export const saveToken = (token: string, data?: RefreshData) => {
    if (!token) return null;
    localStorage.setItem("jwt_token", token);
};

export const deleteToken = () => {
    localStorage.removeItem("jwt_token");
};

export const saveUserId = (webLink: string, userId: string) => {
    localStorage.setItem(webLink, userId);
};

export const getUserId = (webLink: string) => {
    const userId = localStorage.getItem(webLink);
    if (!userId) return null;
    return userId;
};