import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThunkD } from "../../../../types";
import { createSurveyModalOpen } from "../../../../../redux/selectors";
import { logOutUser } from "../../../../../app/Auth/services";
import { UserType } from "../../../../../app/Auth/types";
import HeaderComponent from "../components/HeaderComponent";
import { openCreateSurveyModal } from "../../../../../app/CreateSurvey/actions";
import CreateSurvey from "../../../../../app/CreateSurvey/containers/CreateSurvey";

const Header = (props: { auth: { isAuthenticated: boolean; isLoading: boolean | null; user: UserType } }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const isCreateSurveyModalOpen = useSelector(createSurveyModalOpen);
    const dispatch: ThunkD = useDispatch();

    const history = useHistory();

    const logOut = async () => {
        const resData = await dispatch(logOutUser());
        if (resData.success) {
            history.push(`${process.env.PUBLIC_URL}/sign-in`);
        }
    };

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const onSubmit = async (data?: { name?: string }) => {
        return history.push(`${process.env.PUBLIC_URL}/search?name=${data.name}`);
    };

    return (
        <>
            <HeaderComponent
                auth={props.auth}
                logOut={logOut}
                onSubmit={onSubmit}
                openMenu={openMenu}
                closeMenu={closeMenu}
                anchorEl={anchorEl}
                openCreateModal={() => dispatch(openCreateSurveyModal())}
            />
            {isCreateSurveyModalOpen && <CreateSurvey />}
        </>
    );
};

export default Header;
