import React, { ReactNode } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { Field } from "formik";

import "./input.scss";

type Props = {
  name: string;
  placeholder: string;
  submitFunc: () => void;
  submitFuncClearIcon: () => void;
  searchIcon: ReactNode;
  searchValue: string;
  isHeader?: boolean;
  style?: any;
};

const SearchInput = ({
  name,
  placeholder,
  submitFunc,
  submitFuncClearIcon,
  searchIcon,
  searchValue,
  isHeader,
  style,
}: Props) => {
  return (
    <div className={isHeader ? "search-input--header-wrapper" : "search-input--wrapper"} style={style}>
      <button type="button" onClick={submitFunc} className="search-input__search-btn">
        {searchIcon}
      </button>
      <Field
        name={name}
        value={searchValue}
        placeholder={placeholder}
        type="text"
        className="search-input__input"
        onBlur={submitFunc}
      />
      <button
        type="button"
        onClick={submitFuncClearIcon}
        className={`search-input__cancel-btn ${searchValue ? "search-input__cancel-btn--visible" : ""}`}
      >
        <CancelIcon color="action" fontSize="small" />
      </button>
    </div>
  );
};

export default SearchInput;
