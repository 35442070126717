import React, { useReducer, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  CheckBoxOutlineBlank,
  RadioButtonUncheckedOutlined,
  RemoveCircleOutline as RemoveIcon,
  AddCircleOutline as AddIcon,
  Edit,
  Delete,
  TextFields,
} from "@material-ui/icons";
import Input from "../../../../shared/components/Input/Input";
import reducer from "./reducer";
import { setLoading, setQuestionTypes, setTypeOfQuestion } from "./actions";
import { getQuestionTypesOptions } from "./services";
import { AddQuestionState } from "./types";
// CREATE SURVEY IMPORTS
import { fetchQuestionTypes } from "../../services";
import { Survey } from "../../types";
import { QuestionType } from "../../../Survey/types";
// END

import { isEmpty } from "../../../../utils/helpers";
import { ANSWER } from "../../../../utils/validationParams";
import { SelectField } from "../../../../shared/components/Input/Select";
import { addQuestions } from "../../../Survey/services";

import "./surveyQuestion.scss";

import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import ListIcon from "@material-ui/icons/List";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import SubQuestion from "./SubQuestionsModal.jsx";

// TYPES
type Answer = {
  answer: string;
  order: number;
};
type FormikValues = {
  question_type: string;
  question: string;
  answers: any;
};
export type AddQuestionData = {
  question: string;
  type: string;
  surveyId: number;
  answers: Array<Answer>;
  order: number;
};

type Props = {
  handleClose: () => void;
  onSubmit: () => void;
  survey?: Survey;
  editedQuestion?: QuestionType;
  error?: string;
  isLoading: boolean;
  editPage?: boolean;
  questions: any;
  setUpdateQuestions?: any;
};
// END

const initialState: AddQuestionState = {
  questionType: "",
  isLoading: false,
  types: [],
};
export const QUESTION_ICON = {
  DROPDOWN: <UnfoldMoreIcon />,
  MULTIPLE_CHOICE: <ListIcon />,
  CHECKBOX: <CheckBoxIcon />,
  TEXT: <TextFields />,
};

const AddQuestionEditComponent = (props: Props) => {
  const [state, dispatchAction] = useReducer(reducer, initialState);

  useEffect(() => {
    getTypesOfQuestion();
  }, []);

  const getTypesOfQuestion = async () => {
    dispatchAction(setLoading(true));
    const resData = await fetchQuestionTypes();
    dispatchAction(setLoading(false));
    if (!isEmpty(resData) && !isEmpty(resData.data)) {
      return dispatchAction(setQuestionTypes(resData.data));
    }
    console.log("questions were not found", resData);
  };

  const onQuestionTypeChange = (value: string) => {
    dispatchAction(setTypeOfQuestion(value));
  };

  const getInitialFormValues = () => {
    let result = {
      question: "",
      question_type: typeOptions[0].value,
      answers: [
        {
          answer: "",
          order: 0,
        },
        {
          answer: "",
          order: 1,
        },
        {
          answer: "",
          order: 2,
        },
      ],
    };
    return result;
  };

  const getValidationSchema = () => {
    const result = {
      question: Yup.string()
        .min(1, "Question must be at least 1 character")
        .max(5000, "Question must be at most 5000 characters")
        .trim()
        .required("Question is required"),
      question_type: Yup.string().required("Question type required"),
      answers:
        state.questionType === "TEXT"
          ? Yup.array().nullable()
          : Yup.array().of(
              Yup.object({
                answer: Yup.string()
                  .min(ANSWER.min.char, ANSWER.min.error)
                  .max(ANSWER.max.char, ANSWER.max.error)
                  .trim()
                  .required("Answer is required"),
              })
            ),
    };
    return result;
  };

  const addQuestionSchema = Yup.object().shape(getValidationSchema());

  const renderAnswer = (values, errors, touched, arrayHelpers, setFieldValue) => {
    let inputIcon = <div className="answer-input-icon" />;
    if (state.questionType === "CHECKBOX") {
      inputIcon = <CheckBoxOutlineBlank className="answer-input-icon material" />;
    } else if (state.questionType === "MULTIPLE_CHOICE") {
      inputIcon = <RadioButtonUncheckedOutlined className="answer-input-icon material" />;
    }

    const addSubQuestions = (addIndex) => {
      if (!values.answers[addIndex].hasOwnProperty("followUpQuestions")) {
        setFieldValue(`answers[${addIndex}].followUpQuestions`, [{ open: true }]);
      } else {
        setFieldValue(`answers[${addIndex}].followUpQuestions`, [
          ...values.answers[addIndex].followUpQuestions,
          { open: true },
        ]);
      }
    };

    const editSubQuestion = (editIndex, followUpQuestionsIndex) => {
      const tempaArr = values.answers[editIndex].followUpQuestions.map((item, idx) => {
        if (idx === followUpQuestionsIndex) {
          item.open = true;
        }
        return item;
      });
      setFieldValue(`answers[${editIndex}].followUpQuestions`, tempaArr);
    };

    const removeQuestions = (removeIndex, followUpQuestionsIndex) => {
      setFieldValue(
        `answers[${removeIndex}].followUpQuestions`,
        values.answers[removeIndex].followUpQuestions.filter((item, index) => index !== followUpQuestionsIndex)
      );
    };

    return values.answers.map((answer, i) => {
      const name = `answers.${i}.answer`;
      return (
        <React.Fragment key={i}>
          <div className="input-container" key={i}>
            {inputIcon}
            <Input
              error={
                touched?.answers &&
                touched?.answers[i] &&
                errors?.answers &&
                errors?.answers[i] &&
                errors?.answers[i]?.answer
              }
              touched={touched.answers && touched.answers[i]}
              name={name}
              value={values.answers[i].answer}
              placeholder="Enter an answer"
              type="text"
            />
            <div className="answers-btns">
              <AddIcon className="add-answer-icon" onClick={() => arrayHelpers.insert(i + 1, "")} />
              <RemoveIcon
                className="delete-answer-icon"
                onClick={() => {
                  if (values.answers.length === 1) return;
                  arrayHelpers.remove(i);
                }}
              />
            </div>
            <div className="sub-question-btn-wrapper">
              <button type="button" onClick={() => addSubQuestions(i)} className="primary-btn">
                Add Sub Question
              </button>
            </div>
          </div>
          {answer?.followUpQuestions &&
            answer?.followUpQuestions?.length > 0 &&
            answer.followUpQuestions.map((item, index) => {
              return (
                <div className="sub-question-representative" key={item.id}>
                  <div>{item.question}</div>
                  <div className="sub-question-representative-btns">
                    <Edit onClick={() => editSubQuestion(i, index)} />
                    <Delete
                      onClick={() => {
                        removeQuestions(i, index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          {values?.answers[i] &&
            values?.answers[i]?.followUpQuestions &&
            values?.answers[i]?.followUpQuestions?.map((item, index) => {
              const tempItem = { ...item };
              delete tempItem.open;

              return (
                <SubQuestion
                  key={index}
                  i={index}
                  state={state}
                  previousVal={item}
                  setPreviousVal={(value) => setFieldValue(`answers[${i}].followUpQuestions[${index}]`, value)}
                  isOpenModal={item.open}
                  handleClose={() => {
                    setFieldValue(`answers[${i}].followUpQuestions[${index}]`, tempItem);
                    if (!tempItem.question) {
                      setFieldValue(
                        `answers[${i}].followUpQuestions`,
                        values.answers[i].followUpQuestions.filter((item, idx) => idx !== index)
                      );
                    }
                  }}
                />
              );
            })}
        </React.Fragment>
      );
    });
  };

  const getFormattedQuestion = (data: FormikValues, index?: number) => {
    const result = {
      type: data.question_type,
      question: data.question,
      answers: [],
      order: index,
    };
    result.answers =
      data.question_type === "TEXT"
        ? null
        : data.answers.map((value, i) => {
            value["order"] = i;
            return value;
          });
    return result;
  };

  function removeEmptyFollowUpQuestions(questions) {
    return questions.map((question) => {
      const { followUpQuestions, ...rest } = question;

      if (followUpQuestions && followUpQuestions.length === 0) {
        return rest;
      } else if (followUpQuestions) {
        rest.followUpQuestions = removeEmptyFollowUpQuestions(followUpQuestions);
      }

      if (rest.answers) {
        rest.answers = removeEmptyFollowUpQuestions(rest.answers);
      }

      return rest;
    });
  }

  function setAnswersToNull(questions) {
    return questions.map((question) => {
      if (question.type === "TEXT") {
        return { ...question, answers: null };
      }

      if (question.followUpQuestions) {
        question.followUpQuestions = setAnswersToNull(question.followUpQuestions);
      }

      if (question.answers) {
        question.answers = setAnswersToNull(question.answers);
      }

      return question;
    });
  }

  const onSubmit = async (values: FormikValues) => {
    const result = [];
    const idx = props?.questions ? props?.questions[props?.questions?.length - 1].order : 0;
    result.push(getFormattedQuestion(values, idx));

    const newResult = setAnswersToNull(removeEmptyFollowUpQuestions(result));

    const questions = {
      questions: newResult,
      surveyId: props.survey ? props.survey.id : null,
    };
    await addQuestions(questions);
    props.handleClose();
    await props.onSubmit();

    console.log("edit add idx", idx);

    props.setUpdateQuestions && props.setUpdateQuestions(true);
  };

  const typeOptions = getQuestionTypesOptions(state.types);

  return (
    <div className="add-survey-question-container">
      <div className="content">
        {!isEmpty(state.types) && (
          <Formik
            validateOnBlur={true}
            initialValues={getInitialFormValues()}
            validationSchema={addQuestionSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, touched }) => {
              return (
                <Form>
                  <Input
                    error={touched.question && errors.question}
                    touched={touched.question}
                    name="question"
                    value={values.question}
                    placeholder="Question"
                    type="text"
                  />

                  <Field
                    component={SelectField}
                    options={typeOptions}
                    handleSelectChange={onQuestionTypeChange}
                    defaultValue={typeOptions[0]}
                    name="question_type"
                    className="custom-select"
                  />

                  {values.question_type !== "TEXT" && (
                    <FieldArray
                      name={"answers"}
                      render={(arrayHelpers) => {
                        return renderAnswer(values, errors, touched, arrayHelpers, setFieldValue);
                      }}
                    />
                  )}

                  <div className="questions-buttons-wrapper">
                    <button type="button" className="secondary-btn" onClick={props.handleClose}>
                      Cancel
                    </button>
                    <button type="submit" className="primary-btn">
                      Save
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AddQuestionEditComponent;
