import React, { useState } from "react";

type Props = {
  link: string;
  handleClose: () => void;
};

const WebLink = (props: Props) => {
  const [copied, setCopied] = useState(false);
  let parsedWeblink = null;
  if (props.link) {
    let link = `${process.env.REACT_APP_CLIENT_URL}`;
    link = link.substr(link.length - 1) === "/" ? link.substr(0, link.length - 1) : link;
    parsedWeblink = link + `/survey?webLink=${props.link}`;
  }
  return (
    <>
      <div className="webLink-wrapper">
        <div className="input-block plain">
          <input
            name="link"
            value={parsedWeblink}
            placeholder="Web link is not reachable, survey is not active"
            type="text"
            disabled={true}
          />
        </div>
        <button
          className="primary-btn"
          disabled={!parsedWeblink}
          onClick={() => {
            navigator.clipboard.writeText(parsedWeblink);
            setCopied(true);
          }}
        >
          {copied ? "Copied" : "Copy"}
        </button>
      </div>
      <div className="buttons-wrapper">
        <button type="button" className="secondary-btn" onClick={props.handleClose}>
          Close
        </button>
      </div>
    </>
  );
};

export default WebLink;
