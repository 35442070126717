import request from "../../utils/request";
import { getResData } from "../../utils/helpers";
import { FormatTypes } from "./types";

export const fetchFormatTypes = async (): Promise<{ data: FormatTypes }> => {
  try {
    const res = await request.get("/user/survey/format/types");
    return getResData(res.data);
  } catch (error) {
    console.error("fetchFormatTypes error => ", error);
    console.log("Error Response fetchFormatTypes => ", error.response);
    return getResData(error.response, true);
  }
};

export const fetchCategoriesTypes = async () => {
  try {
    const res = await request.get("/user/survey/category/types");
    return getResData(res.data);
  } catch (error) {
    console.error("fetchCategoriesTypes error => ", error);
    console.log("Error Response fetchCategoriesTypes => ", error.response);
    return getResData(error.response, true);
  }
};

export const editSurvey = async (survey) => {
  try {
    const res = await request.post("/user/survey/edit", survey);
    return getResData(res.data);
  } catch (error) {
    console.error("editSurvey error => ", error);
    console.log("Error Response editSurvey => ", error.response);
    return getResData(error.response, true);
  }
};
