import {
    SET_FORMAT_TYPES,
    SET_LOADING_TYPE,
    REMOVE_LOADING_TYPE,
    SET_SURVEY,
    SET_ERROR,
    SET_STEP,
    OPEN_CREATE_SURVEY_MODAL,
    CLOSE_CREATE_SURVEY_MODAL,
    PUSH_QUESTION,
    SET_QUESTION_STEP,
    PULL_QUESTION,
    SET_SURVEY_ID,
    SET_CATEGORY_TYPES,
    SET_SURVEY_COLLECTORS,
} from "./constants";
import {
    SetFormatTypesAction,
    FormatTypes,
    LoadingTypeAction,
    Survey,
    SetSurveyAction,
    SetSurveyErrorAction,
    SetStepAction,
    PushQuestionAction,
    SetQuestionStepAction,
    PullQuestionAction,
    SetSurveyIdAction,
    SetCategoryTypesAction,
    SetSurveyCollectors,
    Collector,
} from "./types";

export const setLoadingType = (loadingType: string): LoadingTypeAction => ({
    type: SET_LOADING_TYPE,
    payload: loadingType,
});

export const removeLoadingType = (loadingType: string): LoadingTypeAction => ({
    type: REMOVE_LOADING_TYPE,
    payload: loadingType,
});

export const setFormatTypes = (formatTypes: FormatTypes): SetFormatTypesAction => ({
    type: SET_FORMAT_TYPES,
    payload: formatTypes,
});

export const setCategoryTypes = (categoryTypes: string[]): SetCategoryTypesAction => ({
    type: SET_CATEGORY_TYPES,
    payload: categoryTypes,
});

export const setSurvey = (survey: Survey): SetSurveyAction => ({
    type: SET_SURVEY,
    payload: survey,
});

export const setError = (error: string): SetSurveyErrorAction => ({
    type: SET_ERROR,
    payload: error,
});

// modal
export const openCreateSurveyModal = () => ({ type: OPEN_CREATE_SURVEY_MODAL });
export const closeCreateSurveyModal = () => ({ type: CLOSE_CREATE_SURVEY_MODAL });

export const pushQuestion = (question: object, insertToIndex?: number): PushQuestionAction => ({
    type: PUSH_QUESTION,
    payload: {
        question,
        insertToIndex,
    },
});
export const setStep = (step: number, location?: string): SetStepAction => ({
    type: SET_STEP,
    payload: step,
    location,
});
export const setSurveyId = (step: number): SetSurveyIdAction => ({
    type: SET_SURVEY_ID,
    payload: step,
});
export const setQuestionStep = (step: number): SetQuestionStepAction => ({
    type: SET_QUESTION_STEP,
    payload: step,
});
export const pullQuestion = (index: number): PullQuestionAction => ({
    type: PULL_QUESTION,
    payload: index,
});

export const setSurveyCollectors = (collectors: Array<Collector>): SetSurveyCollectors => ({
    type: SET_SURVEY_COLLECTORS,
    payload: collectors,
});
// end
