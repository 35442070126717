import { cloneDeep } from "lodash";
import { SET_LOADING, SET_TYPE_OF_QUESTION, SET_QUESTION_TYPES, SET_STATE } from "./constants";
import { AddQuestionState, AddQuestionActionTypes } from "./types";

export default function reducer(state: AddQuestionState, action: AddQuestionActionTypes): AddQuestionState {
    switch (action.type) {
        case SET_LOADING: {
            const newState = cloneDeep(state);
            newState.isLoading = action.payload;
            return newState;
        }

        case SET_TYPE_OF_QUESTION: {
            const newState = cloneDeep(state);
            newState.questionType = action.payload;
            return newState;
        }

        case SET_QUESTION_TYPES: {
            const newState = cloneDeep(state);
            newState.types = action.payload;
            newState.questionType = action.payload[0];
            return newState;
        }

        case SET_STATE: {
            return action.payload;
        }

        default:
            console.log(action);
            throw new Error();
    }
}
