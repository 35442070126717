import React, { useState, forwardRef, useImperativeHandle } from "react";
import _uniqBy from "lodash/uniqBy";
import Checkbox from "@material-ui/core/Checkbox";
import { SelectAsyncPaginate } from "../../../../shared/components/Input/Select";
import { isEmpty } from "../../../../utils/helpers";
import { Option } from "react-select/src/filters";
import { fetchContacts } from "../../../Contacts/services";
import "../../styles/contactsSelector.scss";

type Props = {
    // onSubmit: (error: string | null, result: { selectAll: boolean; participants: Array<string> } | null) => void;
};

interface IOption extends Option {
    id: number;
}

type Result = {
    participants: Array<string>;
    selectAll: boolean;
};
export interface IOnSubmit {
    error: string | null;
    result: Result | null;
}

const AddCollectorContacts = forwardRef((props: Props, ref) => {
    const [errors, setErrors] = useState<{ select: string; general: string }>({ select: "", general: "" });
    const [selectedContacts, setSelectedContacts] = useState<Array<number>>([]);
    const [contacts, setContacts] = useState<{ data: Array<IOption>; total: number }>({ data: [], total: 0 });
    const [selectAll, setSelectAll] = useState(false);

    useImperativeHandle(ref, () => ({
        onSubmit(): IOnSubmit {
            const result = {
                participants: [],
                selectAll,
            };
            if (isEmpty(selectedContacts) && !selectAll) {
                const error = "Please add at least one contact!";
                setErrors({ ...errors, select: error });
                return {
                    error,
                    result: null,
                };
            }
            contacts.data.forEach((contact) => {
                if (selectedContacts.includes(contact.id)) {
                    result.participants.push(contact.value);
                }
            });
            if (errors.general || errors.select) setErrors({ ...errors, general: "", select: "" });

            return {
                error: null,
                result,
            };
        },
    }));

    const onContactsChange = async (option: Array<IOption>) => {
        const result = [...selectedContacts];
        const index = result.findIndex((selectedOptionId) => selectedOptionId === option[0].id);
        if (index !== -1) {
            result.splice(index, 1);
        } else {
            if (errors.select) setErrors({ ...errors, select: "" });
            result.push(option[0].id);
        }
        setSelectedContacts(result);
    };

    const loadOptions = async (paginationData, loadedOptions: Array<IOption>) => {
        try {
            const resData = await fetchContacts(paginationData);
            if (resData.result && resData.data) {
                const options = resData.data.map((contact) => ({
                    label: contact.msisdn,
                    value: contact.msisdn,
                    id: contact.id,
                }));

                const newContacts = _uniqBy([...loadedOptions, ...contacts.data, ...options], "id");
                setContacts({ data: newContacts, total: resData.total });
                return options;
            }
        } catch (error) {
            console.log("loadOptions error", error);
            return [];
        }
    };

    const onCheckBoxChange = () => {
        if (!selectAll && errors.select) {
            setErrors({ ...errors, select: "" });
        }
        setSelectAll(!selectAll);
        setSelectedContacts([]);
    };

    return (
        <div className="contacts-selector-container">
            <div className="select-all-container">
                Select all
                <Checkbox checked={selectAll} onChange={onCheckBoxChange} />
            </div>
            <SelectAsyncPaginate
                handleSelectChange={onContactsChange}
                name="contact"
                isMulti={true}
                isClearable={true}
                isSearchable={true}
                error={errors.select}
                selectedOptions={selectedContacts}
                className="custom-select"
                loadOptions={loadOptions}
                selectAll={selectAll}
                total={contacts.total}
                initialPlaceholder="Add Contacts"
            />
        </div>
    );
});

export default AddCollectorContacts;
