import React from "react";
import Notification from "../Notification";
import "./styles/layout.scss";

const Layout = (props: any) => {
    return (
        <>
            <div className="layout">{props.children}</div>
            <Notification />
        </>
    );
};

export default Layout;
