import React, { useEffect, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { isEmpty } from "../../../utils/helpers";

const CustomCheckbox = ({ option, fieldName, form, field, setSaved }) => {
  const [checked, setChecked] = useState(
    field && field?.value?.filter((item) => item !== Number(option.value))?.length ? true : false
  );

  useEffect(() => {
    if (field.value) {
      const tempArray = field.value.filter((item) => {
        if (Number(item)) {
          return Number(item) !== Number(option.value);
        } else {
          return item !== option.value;
        }
      });
      if (checked) {
        form.setFieldValue(field.name, [...tempArray, Number(option.value)]);
      } else {
        form.setFieldValue(field.name, [...tempArray]);
      }
    } else {
      if (checked) {
        form.setFieldValue(field.name, [Number(option.value)]);
      } else {
        form.setFieldValue(field.name, []);
      }
    }
  }, [checked]);

  return (
    <FormControlLabel
      key={option.value}
      name={fieldName}
      value={option.value}
      control={<Checkbox />}
      label={option.label}
      onChange={() => {
        setChecked(!checked);
        setSaved(false);
      }}
    />
  );
};

const MultipleChoice = (props) => {
  const [saved, setSaved] = useState(false);
  const { field, error, form, name, options, value } = props;
  const fieldName = name || field.name;

  return (
    <React.Fragment>
      <FormGroup {...field} {...props} name={fieldName}>
        {options.map((option) => (
          <CustomCheckbox
            key={option?.value}
            option={option}
            field={field}
            fieldName={fieldName}
            form={form}
            setSaved={setSaved}
          />
        ))}
      </FormGroup>
      {props.scrollable && !isEmpty(value) && !saved && (
        <button
          type="button"
          className="primary-btn scroll-btn"
          onClick={() => {
            props.scrollToElement(props.id);
            setSaved(true);
          }}
        >
          Ok
        </button>
      )}

      {error && <p className="error-text">{form.errors[field.name]}</p>}
    </React.Fragment>
  );
};

export default MultipleChoice;
